import "./style.css";
import {useState} from "react";
import company_logo from "../../../../assets/images/company_logo.svg";

export default function LogosBlock(){
    let [logos, setLogos]=useState([
        {
            id:1,
            logo:company_logo,
        },
        {
            id:2,
            logo:company_logo,
        },
        {
            id:3,
            logo:company_logo,
        },
        {
            id:4,
            logo:company_logo,
        },
    ])
    return (
        <div className="logos_cont">
            <div className="container  d_flex space_between align_items_center">
                {
                    logos.map((item ,index)=>{
                        return (
                            <div className="logos_cont_item" key={item.id}>
                                <img src={item.logo} alt="company_logo"/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}