import "../../style.css";
import {dividerClasses} from "@mui/material";
import {useState} from "react";
import send_black_icon from "../../../../../assets/images/icons/send_black_icon.png";
import img from "../../../../../assets/images/img.png";


export default function Messenger() {
    let [message, setMessage] = useState([
        {
            img: img,
            name: "John Smith",
            time: "1:07",
            message: "Lorem ipsum dolor sit amet consectetur."
        },
        {
            img: img,
            name: "Henry Martin",
            time: "1:07",
            message: "Lorem ipsum dolor sit amet consectetur."
        },

    ])
    let [writeMessage, setWriteMessage] = useState("")

    let handleChangeMessenger = (e) => {
        setWriteMessage(e.target.value)
    }

    let sendMessage = () => {
        if (writeMessage){
            message.push({
                id: "",
                img: img,
                name: "John Smith",
                time: new Date().getDate(),
                message: writeMessage
            })
            setMessage([...message])
            setWriteMessage("")
        }
    }
    let handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage()
        }
    }

    return (
        <div className="messenger_cont margin_top_24">
            <div className="messages_block d_flex fd_column">
                {
                    message.map((item, index) => {
                        return (
                            <div className="d_flex align_items_start messenger_block_item" key={index}>
                                <div className="messenger_block_item_img_b">
                                    <img src={item.img} alt="img"/>
                                </div>
                                <div className="messenger_block_item_info d_flex fd_column align_items_start">
                                    <p className="fs_18 f_600">{item.name}</p>
                                    <span className="fs_12">{item.time}PM</span>
                                    <div className="margin_top_8 fs_16">{item.message}</div>
                                </div>

                            </div>
                        )
                    })
                }
            </div>
            <div className="send_message_block d_flex align_items_center">
                <input type="text" placeholder="Message..." onChange={handleChangeMessenger} value={writeMessage}
                       onKeyPress={handleKeyPress}/>
                <button className="message_send_btn" onClick={sendMessage}><img src={send_black_icon} alt=""/></button>
            </div>
        </div>
    )
}