import "./style.css";
import {Outlet} from "react-router-dom";
import Header from "../../components/header/header";
import Sidebar from "../../components/sidebar/sidebar";
import sidebar_icon from "../../assets/images/icons/sidebar_icon.png";
import 'swiper/css';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper';
import {useState} from "react";


export default function Dashboard(){
    let [openSidebar, setOpenSidebar]=useState(true)
    return (
        <div className="">
            {
                openSidebar ?
                    <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar}/>
                    :
                    null
            }
            <img src={sidebar_icon} alt="icon" className="dashboard_sidebar_icon" onClick={()=>setOpenSidebar(!openSidebar)}/>


            {
                openSidebar ?
                    <div
                        className="content_sidebar_open"
                    >
                        <Outlet />
                    </div>
                    :
                    <div className="content_sidebar_close">
                        <Outlet/>
                    </div>

            }

        </div>
    )
}