import "./style.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import sign_up_pic from "../../assets/images/sign_up_pic.svg";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import config from "../../config";
import axios from "axios";


export default function SignUp() {
    let navigate = useNavigate()
    let [country, setCountry] = useState([])
    const role = [
        {
            _id: '65537f4334091abb17b63479',
            name: 'admin'
        },
        {
            _id: '65537f4334091abb17b6347f',
            name: 'user'
        }
    ]
    const [signUp, setSignUp] = useState({
        countryId: "",
        companyName: "",
        registrationNo: "",
        fullName: "",
        email: "",
        phoneNo: "",
        roleId: "65537f4334091abb17b6347f",
        password: "",
        passwordConfirmation: "",
    })
    // console.log(signUp.roleId[1].name, "etete")
    const [error, setError] = useState("")
    const [signUpBtn, setSignUpBtn] = useState(false)
    const [politics, setPolitics] = useState(false)

    const fetchCountries = async () => {
        try {
            const response = await axios.get(`${config.url}/get-countries`, );
            setCountry(response.data);
        } catch (error) {
            setError(error);
        }
    };

    useEffect(() => {
        fetchCountries();
    }, []);

    const handleChangeInput = (e, name) => {
        setSignUp({...signUp, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(signUp.email).toLowerCase());
    }
    let handleCreateAccount = async () => {
        try {
            setSignUpBtn(true)
            if(politics){
                if (signUp.countryId && signUp.companyName && signUp.registrationNo &&
                    signUp.fullName && signUp.phoneNo && signUp.email && signUp.password &&
                    signUp.passwordConfirmation) {
                    if (signUp.password.length > 7) {
                        if (signUp.password === signUp.passwordConfirmation) {
                            if (validateEmail()) {
                                let response = await axios.post(`${config.url}/auth/sign-up`, signUp)
                                console.log(response,"response");
                                navigate('/sign_in')
                            } else {
                                setError("Incorrect email address")
                            }
                        } else {
                            setError("Passwords are different")
                        }
                    } else {
                        setError("Password length is small (min 8 characters)")
                    }
                }
            }else{
                setError("Please check Private Politics")
            }

        }catch (e){
            console.log(e);
            setError(error.response?.message)
        }

    }

    return (
        <div className="main">
            <div>
                <Header/>
                <div className="sign_up_cont">
                    <div className="container d_flex align_items_center space_between sign_up_container">
                        <div className="sign_up_cont_sign_up text_left d_flex fd_column justify_content_start">
                            <h2 className="fs_40 c_white f_700 text_left">Create an account</h2>
                            <div className="inputs_block">
                                <select name="" id="" className="margin_top_16 fs_18"
                                    style={!signUp.countryId && signUpBtn ? {border: "1px solid red"} : null}
                                    onChange={(e) =>
                                    {
                                        handleChangeInput(e.target.value, 'countryId')
                                    }
                                    }
                                >
                                    {
                                        country.map((item, index) => {
                                            return (
                                                <option value={item._id} key={index}>{item.name}</option>
                                            )
                                        })
                                    }
                                </select>
                                <div className="d_flex space_between margin_top_16">
                                    <div className="input_block_item"
                                         style={!signUp.companyName && signUpBtn ? {border: "1px solid red"} : null}>
                                        <input type="text" placeholder="Company Name" className="fs_18"
                                               onChange={(e) =>
                                                   handleChangeInput(e.target.value, 'companyName')}
                                        />
                                    </div>
                                    <div className="input_block_item"
                                         style={!signUp.registrationNo && signUpBtn ? {border: "1px solid red"} : null}>
                                        <input type="text" placeholder="Registration no." className="fs_18"
                                               onChange={(e) =>
                                                   handleChangeInput(e.target.value, 'registrationNo')}
                                        />
                                    </div>
                                </div>
                                <div className="margin_top_32 input_block"
                                     style={!signUp.fullName && signUpBtn ? {border: "1px solid red"} : null}>
                                    <input type="text" placeholder="Your Name" className="fs_18"
                                           onChange={(e) =>
                                               handleChangeInput(e.target.value, 'fullName')}
                                    />
                                </div>
                                <div className="margin_top_16 input_block"
                                     style={!signUp.phoneNo && signUpBtn ? {border: "1px solid red"} : null}>
                                    <input type="text" placeholder="Phone" className="fs_18"
                                           onChange={(e) =>
                                               handleChangeInput(e.target.value, 'phoneNo')}
                                    />
                                </div>
                                <div className="margin_top_16 input_block"
                                     style={!signUp.email && signUpBtn ? {border: "1px solid red"} : null}>
                                    <input type="email" placeholder="Email" className="fs_18"
                                           onChange={(e) =>
                                               handleChangeInput(e.target.value, 'email')}
                                    />
                                </div>
                                <div className="margin_top_16 input_block"
                                     style={!signUp.password && signUpBtn ? {border: "1px solid red"} : null}>
                                    <input type="password" placeholder="Password" className="fs_18"
                                           onChange={(e) =>
                                               handleChangeInput(e.target.value, 'password')}
                                    />
                                </div>
                                <div className="margin_top_16 input_block"
                                     style={!signUp.passwordConfirmation && signUpBtn ? {border: "1px solid red"} : null}>
                                    <input type="password" placeholder="Confirm Password" className="fs_18"
                                           onChange={(e) =>
                                               handleChangeInput(e.target.value, 'passwordConfirmation')}
                                    />
                                </div>
                            </div>
                            <div className="d_flex align_items_center margin_top_32 checkbox_block">
                                <input type="checkbox" name="" value="" onChange={() => {
                                    setPolitics(!politics)
                                    setError('')
                                }}/>
                                <p className="d_flex fd_column justify_content_start fs_14"
                                   style={{color: "#E3E3E3"}}> Lorem
                                    ipsum dolor sit amet consectetur fames varius
                                    <span style={{color: "#E3E3E3"}}>eleifend leo adipiscing.</span></p>
                            </div>
                            {/*<div className="d_flex align_items_center margin_top_32 checkbox_block">*/}
                            {/*    <input id="check1" type="checkbox" name="check" value="check1"/>*/}
                            {/*    <label htmlFor="check1" className="d_flex fd_column justify_content_start fs_14" style={{color: "#E3E3E3"}}> Lorem*/}
                            {/*        ipsum dolor sit amet consectetur fames varius*/}
                            {/*        <span style={{color: "#E3E3E3"}}>eleifend leo adipiscing.</span></label>*/}
                            {/*</div>*/}
                            {error ? <p className="error">{error}</p> : null}
                            <button className="create_account_btn bc_green fs_20 f_600 margin_top_32"
                                    onClick={handleCreateAccount}>Create Account
                            </button>
                            <div className="margin_top_16 have_account_b fs_18 text_center">
                                Already have an account?
                                <span className="c_green fs_18 f_600"
                                      onClick={() => navigate("/sign_in")}>Sign in</span>
                            </div>
                        </div>
                        <div className="sign_up_cont_img_b">
                            <img src={sign_up_pic} alt="sign_up_pic" className="sign_up_pic"/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
