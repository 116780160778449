import "./style.css";
import send_icon from "../../../../assets/images/icons/send_icon.svg";
import delivery_icon from "../../../../assets/images/icons/delivery-truck_icon.svg";
import wallet_icon from "../../../../assets/images/icons/wallet_icon.svg";



export default function HowWorks() {
    return (
        <div className="how_works_cont d_flex space_between">
            <div className="how_works_cont_item1">
                <h2 className="fs_40 f_700 text_left">How it work?</h2>
                <div className="fs_18 margin_top_16 text_left">
                    Lorem ipsum dolor sit amet consectetur. Duis enim nunc ut praesent. Nam magnis integer consectetur
                    in sagittis est. In mauris diam vitae nam fermentum amet. Lorem ipsum dolor sit amet.
                </div>
                <div className="d_flex justify_content_start">
                    <button className="button_full margin_top_32">Get Started</button>
                </div>
            </div>
            <div className="how_works_cont_item bc_darkGreen d_flex fd_column justify_content_start align_items_start">
                <img src={send_icon} alt="wallet_icon"/>
                <p className="margin_top_12 fs_20 f_600 c_white">Send a package</p>
                <div className="fs_18 margin_top_12 text_left c_white">
                    Lorem ipsum dolor sit amet consectetur. Duis enim nunc  praesent. Nam magnis integer. consectetur in sagittis est.
                </div>
            </div>
            <div className="how_works_cont_item bc_darkGreen d_flex fd_column justify_content_start align_items_start">
                <img src={delivery_icon} alt="wallet_icon"/>
                <p className="margin_top_12 fs_20 f_600 c_white">Get it where you need it</p>
                <div className="fs_18 margin_top_12 text_left c_white">
                    Lorem ipsum dolor sit amet consectetur. Duis enim nunc ut praesent. Nam magnis integer consectetur
                    in sagittis est. In mauris diam vitae nam fermentum amet. Lorem ipsum dolor sit amet.
                </div>
            </div>
            <div className="how_works_cont_item bc_darkGreen d_flex fd_column justify_content_start align_items_start">
                <img src={wallet_icon} alt="wallet_icon"/>
                <p className="margin_top_16 fs_20 f_600 c_white">Pay immediately on the spot</p>
                <div className="fs_18 margin_top_12 text_left c_white">
                    Lorem ipsum dolor sit amet consectetur. Duis enim nunc ut praesent. Nam magnis integer consectetur
                    in sagittis est. In mauris diam vitae nam fermentum amet. Lorem ipsum dolor sit amet.
                </div>
            </div>
        </div>
    )
}
