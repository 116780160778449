import "./style.css";
import {useNavigate} from "react-router-dom";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import HomeBlock1 from "./components/homeBlock1/HomeBlock1";
import LogosBlock from "./components/logosBlock/LogosBlock";
import HowWorks from "./components/howWorks/HowWorks";
import HomeInfoBlock from "./components/homeInfoBlock/HomeInfoBlock";


export default function Home() {
    let navigate = useNavigate()


    return (
        <div className="main">
            <div>
                <Header/>
                <HomeBlock1/>
                <LogosBlock/>
                <div className="container">
                    <HowWorks/>
                    <HomeInfoBlock/>
                </div>
                <div className="stay_with_cont">
                    <div className="container">
                        <h2 className="fs_40 f_700 c_white">Stay with <span className="c_green f_700">Logicore</span></h2>
                        <div className="fs_18 f_400 c_white margin_top_16">Subscribe to our newsletter and never miss our news.</div>
                        <div className="margin_top_32">
                            <input type="text" placeholder="Email" className="fs_18"/>
                            <button className="button_full">Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}