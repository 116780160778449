import "../../style.css";
import Dropdown from "../../../../../components/dropdown/Dropdown";
import {useState} from "react";

export default function SettingsComponent() {
    const [settingsData, setSettingsData] = useState([
        {
            name: 'Label printer',
            key: 'label printer',
            data: [
                {
                    id: 1,
                    name: "Label printer",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Label printer 2",
                    checked: false,
                },
            ]
        },
    ])
    return (
        <div className="settings_content_container">
            <div className="d_flex space_between align_items_start settings_tab_content">
                <div className="d_flex fd_column align_items_start settings_tab_content_1">
                    <div>
                        <input type="text" placeholder="John Smith"/>
                    </div>
                    <div className="margin_top_16">
                        <input type="email" placeholder="john@sm.example.com"/>
                    </div>
                    <div className="margin_top_16">
                        <input type="text" placeholder="+1 345 677 879"/>
                    </div>
                </div>
                <div className="d_flex fd_column align_items_start settings_tab_content_2">
                    <div className="d_flex align_items_center">
                        <input type="checkbox"/>
                        <label htmlFor="">Weekly reports by e-mail</label>
                    </div>
                    <div className="margin_top_16 d_flex align_items_center">
                        <input type="checkbox" checked="checked"/>
                        <label htmlFor="">Notifications by e-mail</label>
                    </div>
                    <div className="margin_top_24 d_flex fd_column dropdown_div justify_content_start align_items_start">
                        <label htmlFor="" className="f_600">Print label after booking shipment</label>
                        <div className="margin_top_8 dropdown_block">
                            {
                                settingsData.map((item, index) => {
                                    return (
                                        <Dropdown
                                            key={index}
                                            data={item.data}
                                            name={item.name}
                                            filterItemDsta={settingsData}
                                            changeDropdown={(itemDataIndex) => {
                                                let data =settingsData[index].data
                                                for(let i=0; i < settingsData[index].data.length; i++) {
                                                    data[i].checked = false
                                                }
                                                data[itemDataIndex].checked = true
                                                setSettingsData([...settingsData])
                                                // setFilterData({...filterData, [item.key]: data[itemDataIndex].name})
                                            }}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="save_preferences_btn_b d_flex align_items_end justify_content_end margin_top_32">
                <button className="bc_green fs_16 f_600">Save Preferences</button>
            </div>

        </div>
    )
}
