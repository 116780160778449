import "./style.css";
import arrow_down from "../../../../../assets/images/icons/arrow_down_icon_black.png";
import {useEffect, useRef, useState} from "react";

export default function DocumentsRemarks({documentsCategory,setDocumentsCategory}){
    let [showCode, setShowCode]=useState(false)
    let [codeValue, setCodeValue]=useState("")
    let closeShowCodeRef=useRef(null)
    let codeValueRef=useRef(null)

    const handleClickOutSide = (e) => {
        let valueRef = codeValueRef;
        if (valueRef.current && !valueRef.current.contains(e.target) && !closeShowCodeRef.current.contains(e.target)) {
            setShowCode(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])

    return (
        <div className="d_flex space_between incoterms_customers_b margin_top_24">
            <div className="d_flex fd_column incoterms_b">
                <h2 className="fs_20 f_600 text_left">Documents</h2>
                    <div className="type_select_b margin_top_12">
                        <div
                            className={!showCode ? "d_flex align_items_center select_header" : "d_flex align_items_center select_header_active"}
                            onClick={() =>setShowCode(!showCode)}  ref={closeShowCodeRef}>
                            <input type="text" value={codeValue} placeholder="Type*"/>
                            {
                                showCode ?
                                    <img src={arrow_down} alt="arrow"
                                         style={{transform: "rotate(180deg)"}}/>
                                    :
                                    <img src={arrow_down} alt="arrow"/>
                            }

                        </div>
                        {
                            showCode &&
                            <div className="select_dropdown" ref={codeValueRef}>
                                {
                                    documentsCategory.map((item, index) => {
                                        return (
                                            <div className="text_left"
                                                 onClick={()=>{
                                                     setCodeValue(item.option)
                                                     setShowCode(false)
                                                 }
                                                 }
                                            >{item.option}</div>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
            </div>
            <div className="customer_ref_b">
                <h2 className="fs_20 f_600 text_left">Remarks</h2>
                <div className="d_flex  align_items_center new_shipment_input_block margin_top_12">
                    <textarea name="" id="" cols="30" rows="10" placeholder="Info for the carrier">

                    </textarea>
                </div>
            </div>
        </div>
    )
}