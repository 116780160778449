import "./style.css";
import Header from "../../components/header/header";
import sign_up_pic from "../../assets/images/sign_up_pic.svg";
import Footer from "../../components/footer/footer";
import {useNavigate} from "react-router-dom";
import config  from "../../../src/config";
import {useState} from "react";
import axios from "axios";

export default function SignIn() {
    let navigate=useNavigate()
    const [login, setLogin] = useState({
        email: "",
        password:"",
    })
    const [error, setError] = useState("")
    const [loginBtn, setLoginBtn] = useState(false)
    const [rememberMe, setRememberMe] = useState(false)

    const handleChangeInput = (e, name) => {
        setLogin({...login, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(login.email).toLowerCase());
    }

    let SignInUser =()=>{
        let values={
            email:login.email,
            password:login.password,
        }
        setLoginBtn(true)
        if (login.email.length && login.password.length) {
            if (login.password.length > 7) {
                if (validateEmail()) {
                    axios.post(`${config.url}/auth/sign-in`, values)
                        .then(response => {
                            if(rememberMe){
                                localStorage.setItem('jwtToken', JSON.stringify({authToken: response.data.authToken, refreshToken: response.data.refreshToken}))
                            }
                            else{
                                sessionStorage.setItem('jwtToken', JSON.stringify({authToken: response.data.authToken, refreshToken: response.data.refreshToken}))
                            }
                            console.log(response,"response");
                            navigate("/dashboard/shipments")
                        })
                        .catch(error => {
                            console.log(error.response, "error")
                            setError(error.response?.data)
                        })
                } else {
                    setError("Incorrect email address")
                }
            } else {
                setError("Password length is small (min 8 characters)")
            }
        }
    }
    return (
        <div className="main">
            <div>
                <Header/>
                <div className="sign_up_cont">
                    <div className="container d_flex align_items_center space_between sign_up_container">
                        <div className="sign_up_cont_sign_up text_left d_flex fd_column justify_content_start">
                            <h2 className="fs_40 c_white f_700 text_left">Create an account</h2>
                            <div className="inputs_block margin_top_32">
                                <div className="margin_top_16 input_block" style={!login.email && loginBtn ? {border: "1px solid red"} : null}>
                                    <input type="email" placeholder="Email" className="fs_18"
                                           onChange={(e) => handleChangeInput(e.target.value, 'email')}

                                    />
                                </div>
                                <div className="margin_top_16 input_block" style={!login.email && loginBtn ? {border: "1px solid red"} : null}>
                                    <input type="password" placeholder="Password" className="fs_18"
                                           onChange={(e) => handleChangeInput(e.target.value, 'password')}
                                    />
                                </div>
                            </div>
                            <div className="d_flex align_items_center margin_top_32 checkbox_block">
                                <input type="checkbox" name="" value="" onChange={() => setRememberMe(!rememberMe)}/>
                                <p className="d_flex fd_column justify_content_start fs_14"
                                   style={{color: "#E3E3E3"}}>Remember me</p>
                            </div>
                            {error ? <p className="error">{error}</p> : null}
                            <button className="create_account_btn bc_green fs_20 f_600 margin_top_32" onClick={SignInUser}>Sign in
                            </button>
                            <div className="d_flex space_between align_items_center margin_top_48">
                                <div className="have_account_b fs_18 text_left">
                                    Don't have an account?
                                    <span className="c_green fs_18 f_600" onClick={()=>navigate("/sign_up")}>Sign up</span>
                                </div>
                                <div className="forgot_pass fs_18" onClick={()=>navigate("/forgot_password")}>Forgot your password?</div>
                            </div>

                        </div>
                        <div className="sign_up_cont_img_b">
                            <img src={sign_up_pic} alt="sign_up_pic" className="sign_up_pic"/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
