import  "./style.css";
import gen_pic from "../../../../assets/images/home_gen_pic.svg";
import {useState} from "react";


export default function HomeBlock1(){
    let [from, setFrom] = useState([
        {
            id: 1,
            city: "city"
        },
        {
            id: 2,
            city: "city 1"
        },
        {
            id: 3,
            city: "city 2"
        },
    ])
    let [to, setTo] = useState([
        {
            id: 1,
            point: "Collection points"
        },
        {
            id: 2,
            point: "Collection points 1"
        },
        {
            id: 3,
            point: "Collection points 2"
        },
    ])

    return (
        <div className="back_pic_b">
            <div className="d_flex align_items_center">
                <div className="home_back_pic_b1">
                    <img src={gen_pic} alt="gen_pic"/>
                </div>
                <div className="home_back_pic_b2 d_flex fd_column">
                    <h1 className="fs_40 c_white text_left f_700">Receive your parcels safely with <span
                        className="c_green f_700">Logicore</span></h1>
                    <div className="d_flex fd_column inputs_block_container margin_top_32">
                        <div className="d_flex align_items_center inputs_block">
                            <div className="d_flex fd_column align_items_start inputs_block_item">
                                <label htmlFor="" className="c_white fs_18 f_600">From</label>
                                <select name="" id="" className="fs_18">
                                    {
                                        from.map((item, index) => {
                                            return (
                                                <option value="" key={item.id} className="fs_18">{item.city}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className="d_flex fd_column align_items_start inputs_block_item">
                                <label htmlFor="" className="c_white fs_18 f_600">To</label>
                                <select name="" id="" className="fs_18">
                                    {
                                        to.map((item, index) => {
                                            return (
                                                <option value="" key={item.id} className="fs_18">{item.point}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <p className="fs_18 margin_top_24 f_600 c_white text_left">Parcel dimensions</p>
                        <div className="d_flex align_items_center inputs_block margin_top_12">
                            <div className="d_flex fd_column align_items_start inputs_block_item">
                                <input type="text" placeholder="Weight" className="fs_18"/>
                                <span className="fs_18">kg</span>
                            </div>
                            <div className="d_flex fd_column align_items_start inputs_block_item">
                                <input type="text" placeholder="Length" className="fs_18"/>
                                <span className="fs_18">cm</span>
                            </div>
                        </div>
                        <div className="d_flex align_items_center inputs_block margin_top_32">
                            <div className="d_flex fd_column align_items_start inputs_block_item">
                                <input type="text" placeholder="Width" className="fs_18"/>
                                <span className="fs_18">cm</span>
                            </div>
                            <div className="d_flex fd_column align_items_start inputs_block_item">
                                <input type="text" placeholder="Height" className="fs_18"/>
                                <span className="fs_18">cm</span>
                            </div>
                        </div>
                    </div>
                    <div className="d_flex justify_content_end margin_top_32">
                        <button className="button_full fs_20 f_600">Send now</button>
                    </div>
                </div>
            </div>
        </div>
    )
}