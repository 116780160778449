import "./style.css";

export default function TransportBookGoods() {
    return (
        <div className="transport_book_goods_block d_flex">
            <div className="transport_book_goods_block1 d_flex fd_column justify_content_start align_items_start">
                <p className="fs_14" style={{color: "#8B8B8B"}}>Goods</p>
                <div className="d_flex align_items_center" style={{marginTop: "4px"}}>
                    <div className="fs_14 f_600">
                        3 EUR pallets
                    </div>
                    <div className="fs_14 f_600">
                        120x80x100 cm
                    </div>
                    <div className="fs_14 f_600">
                        1234 kg
                    </div>
                    <div className="fs_14 f_600">
                        1.2 LDM
                    </div>
                    <div className="fs_14 f_600">
                        5.76 m³
                    </div>
                    <div className="fs_14 f_600">
                        Spare Parts
                    </div>
                </div>
            </div>
            <div className="transport_book_goods_block2 d_flex fd_column justify_content_start align_items_start">
                <p className="fs_14" style={{color: "#8B8B8B"}}>Info for the carrier:</p>
                <div className="d_flex align_items_center" style={{marginTop: "4px"}}>
                    <div className="fs_14 f_600">
                        “Lorem ipsum dolor sit amet consectetur.”
                    </div>
                </div>
            </div>
        </div>
    )
}