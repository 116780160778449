import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home/Home";
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import Tracking from "./pages/Tracking/Tracking";
import Pricing from "./pages/Pricing/Pricing";
import PrivateRoute from "./components/routing/PrivateRoute";
import Dashboard from "./pages/Dashboard/Dashboard";
import Shipments from "./pages/Dashboard/Shipments/Shipments";
import ShipmentDetails from "./pages/Dashboard/Shipments/components/shipment_details/ShipmentDetails";
import NewShipment from "./pages/Dashboard/NewShipment/NewShipment";
import TransportBook from "./pages/Dashboard/TransportBook/TransportBook";
import TransportBookDetails from "./pages/Dashboard/TransportBookDetails/TransportBookDetails";
import Settings from "./pages/Dashboard/Settings/Settings";
import ForgotPassword from "./pages/ForgotPass/ForgotPass";
import ResetPassword from "./pages/ResetPass/ResetPassword";
import MyCarriers from "./pages/Dashboard/Carriers/MyCarriers";
import AddCarrier from "./pages/Dashboard/Carriers/components/add_carrier/AddCarrier";
import RestorePassword from "./pages/RestorePassword/RestorePassword";





function App() {

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/sign_up" element={<SignUp/>}/>
                    <Route path="/sign_in" element={<SignIn/>}/>
                    <Route path="/forgot_password" element={<ForgotPassword/>}/>
                    <Route path="/email_send" element={<RestorePassword/>}/>
                    <Route path="/reset_password/:token" element={<ResetPassword/>}/>
                    <Route path="/tracking" element={<Tracking/>}/>
                    <Route path="/pricing" element={<Pricing/>}/>

                    <Route element={<PrivateRoute/>}>
                        <Route path="/dashboard" element={<Dashboard/>}>
                            <Route path="shipments" element={<Shipments/>}/>
                            <Route path="shipments/details/:id" element={<ShipmentDetails/>}/>
                            <Route path="shipments/new_shipment" element={<NewShipment/>}/>
                            <Route path="shipments/new_shipment/transport_book" element={<TransportBook/>}/>
                            <Route path="shipments/new_shipment/transport_book/:id"
                                   element={<TransportBookDetails/>}/>
                            <Route path="/dashboard/settings" element={<Settings/>}/>
                            <Route path="/dashboard/carriers" element={<MyCarriers/>}/>
                            <Route path="/dashboard/add_carrier" element={<AddCarrier/>}/>
                        </Route>
                    </Route>

                    {/*<Route path="/new_verifications" element={<NewVerifications/>}>*/}
                    {/*  <Route path="new_verification_requests" element={<NewVerificationRequests/>}/>*/}
                    {/*  <Route path="view_certificate_sell" element={<ViewCertificateSell/>}/>*/}
                    {/*  <Route index path="view_certificate_license" element={<ViewCertificateLicense/>}/>*/}
                    {/*  <Route path="view_certificate_charge" element={<ViewCertificateCharge/>}/>*/}
                    {/*  <Route path="agreement_partnership" element={<ViewAgreementPartnerShip/>}/>*/}
                    {/*</Route>*/}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
