import "./style.css";
import tel_icon from "../../assets/images/icons/tel_icon.png";
import location_icon from "../../assets/images/icons/location_icon.svg";


export default function Footer() {
    return (
        <div className="footer_cont">
            <div className="container d_flex align_items_center space_between">
                <a className="fs_18 c_white d_flex align_items_center" href="tel:+1 523 648 9851">
                    <img src={tel_icon} alt="tel_icon"/>
                    +1 523 648 9851
                </a>
                <div className="c_white fs_18">
                    Copyright © 2023 Logicore
                </div>
                <a className="fs_18 c_white d_flex align_items_center">
                    <img src={location_icon} alt="tel_icon"/>
                    3461 Montreal Road,Ottawa
                </a>
            </div>
        </div>
    )
}