import "./style.css";
import {useEffect, useRef, useState} from "react";
import arrow_down from "../../assets/images/icons/arrow_down_icon_black.png";

export default function Dropdown({
                                     data,
                                     name,
                                     changeDropdown,
                                 }) {
    const [show, setShow] = useState(false)
    let styleDropdownRef = useRef(null)
    let closeStyleDropdownRef = useRef(null)

    const handleClickOutSide = (e) => {
        let styleRef = styleDropdownRef;
        let closeStyleRef = closeStyleDropdownRef;
        if (styleRef.current && !styleRef.current.contains(e.target) && !closeStyleRef.current.contains(e.target)) {
            setShow(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])

    return (
        <div className="dropdown" >
            <div className= "dropdown_header d_flex align_items_center"  ref={closeStyleDropdownRef}
                 onClick={() => setShow(!show)}>
                <input type="text"
                       value={data.findIndex(e => e.checked) === -1 ? name : data[data.findIndex(e => e.checked)].name}
                       className="fs_16 f_500"/>
                {
                    show ?
                        <img src={arrow_down} alt="arrow_down" style={{transform: "rotate(180deg)"}}/>
                        :
                        <img src={arrow_down} alt="arrow_down"/>

                }
            </div>
            {show &&
                <div className="dropdown_content" ref={styleDropdownRef}>
                    {
                        data.map((item, index) => {
                            return (
                                <div className="dropdown_content_item" key={index}
                                     onClick={(e) => {
                                         changeDropdown(index)
                                         setShow(false)
                                     }}
                                >{item.name}</div>
                            )
                        })

                    }

                </div>
            }
        </div>
    )
}
