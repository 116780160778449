import "./style.css";
import logo from "../../assets/images/Venngage LOGICORE LOGO svg 1.png";
import {NavLink, useNavigate} from "react-router-dom";
import sidebar_icon from "../../assets/images/icons/sidebar_icon.png";
import tel_icon from "../../assets/images/icons/tel_icon.png";
import email_icon from "../../assets/images/icons/email_icon.png";


export default function Sidebar({openSidebar,setOpenSidebar}) {
    let navigate = useNavigate()
    return (


        <div className="d_flex fd_column space_between sidebar">
            <div className="d_flex fd_column align_items_start">
                <div onClick={() => navigate("/dashboard/shipments")} className="d_flex"><img src={logo} alt="logo"
                                                                           className="sidebar_logo"/></div>
                <div className="d_flex fd_column align_items_start justify_content_start sidebar_navLinks_b">
                    <NavLink
                        className={({isActive}) => isActive ? 'sidebar_btn_active f_600' : 'sidebar_btn f_600'}
                        to="/dashboard/shipments">Shipments</NavLink>
                    <NavLink
                        className={({isActive}) => isActive ? 'sidebar_btn_active f_600' : 'sidebar_btn f_600'}
                        to="/dashboard/addresses">Addresses</NavLink>
                    <NavLink
                        className={({isActive}) => isActive ? 'sidebar_btn_active f_600' : 'sidebar_btn f_600'}
                        to="/dashboard/statistics">Statistics</NavLink>
                    <NavLink
                        className={({isActive}) => isActive ? 'sidebar_btn_active f_600' : 'sidebar_btn f_600'}
                        to="/dashboard/pricelist">Pricelist</NavLink>
                </div>
            </div>
            <div className="d_flex fd_column align_items_start help_b">
                <p className="c_white fs_20 c_green f_600">Help</p>
                <div>
                    <a href="tel:+1 523 648 9851" className="fs_16"><img src={tel_icon} alt=""/>+1 523 648 9851</a>
                </div>
                <div>
                    <a href="mailto:help@logicore.com" className="fs_16"><img src={email_icon} alt=""/>help@logicore.com</a>
                </div>
            </div>
            <img src={sidebar_icon} alt="icon" className="sidebar_icon" onClick={()=>setOpenSidebar(!openSidebar)}/>
        </div>
    )
}