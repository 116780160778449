import "./style.css";
import arrow_left from "../../../assets/images/icons/arrow_left.png";
import {useLocation, useNavigate} from "react-router-dom";
import tags_icon from "../../../assets/images/icons/tags_icon.png";
import schedule_black_icon from "../../../assets/images/icons/schedule_black_icon.png";
import {useState} from "react";
import CollectionDelivered from "./components/collection_delivered/CollectionDelivered";
import Goods from "./components/goods/Goods";
import IncotermsCustomer from "./components/incoterms_customer/IncotermsCustomer";
import DocumentsRemarks from "./components/documents_remarks/DocumentsRemarks";
import dashboard_header_icon from "../../../assets/images/icons/dashboard_header_icon.png";
import ReadyCollection from "./components/ready_collection/ReadyCollection";
import ToDelivered from "./components/to_delivered/ToDelivered";
import Header from "../../../components/header/header";

export default function NewShipment({state}) {
    let navigate = useNavigate()
    let [goodsType, setGoodsType] = useState([
        {
            id: 1,
            option: "EUR pll (120x80cm)",
        },
        {
            id: 2,
            option: "FIN pll (120x100cm)",
        },
        {
            id: 3,
            option: "20’ Dry Container",
        },
        {
            id: 4,
            option: "Envelope",
        },
    ])

    let [valueCurrency, setValueCurrency] = useState([
        {
            id: 1,
            option: "CAD",
        },
        {
            id: 2,
            option: "USD",
        },
        {
            id: 3,
            option: "EUR",
        },
    ])
    let [code,setCode]=useState([
        {
            id: 1,
            option: "Ex Works",
        },
        {
            id: 2,
            option: "Delivered At Terminal (DAT)",
        },
        {
            id: 3,
            option: "Free Alongside Ship (FAS)",
        },
    ])
    let [documentsCategory, setDocumentsCategory]=useState([
        {
            id: 1,
            option: "Ex Works",
        },
        {
            id: 2,
            option: "Delivered At Terminal (DAT)",
        },
        {
            id: 3,
            option: "Free Alongside Ship (FAS)",
        },

    ])

let location=useLocation()
    console.log(state,"yry")

    return (
        <div className="d_flex fd_column">
            <Header/>
            <div className="go_back_header_btn_b d_flex space_between align_items_start">
                <div className="d_flex align_items_center fs_18 f_600"
                     onClick={(id) => navigate(`../../dashboard/shipments/details/${id}`)}>
                    <img src={arrow_left} alt="arrow_left"/> Go Back
                </div>
            </div>
            {/*<div className="go_back_header">*/}
            {/*    <div className="go_back_header_inner d_flex align_items_start space_between">*/}
            {/*        <div className="d_flex  align_items_center fs_18 f_600"*/}
            {/*             onClick={(id) => navigate(`../../dashboard/shipments/details/${id}`)}>*/}
            {/*            <img src={arrow_left} alt="arrow_left"/> Go Back*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <img src={dashboard_header_icon} alt="logout_b"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="new_shipment_cont d_flex fd_column justify_content_start">
                <div className="new_shipment_content_cont d_flex fd_column">
                    <div className="title_b d_flex align_items_center space_between">
                        <h2 className="fs_24 f_700">New shipment</h2>
                        <div
                            className="tags_input_b d_flex align_items_center justify_content_start new_shipment_input_block">
                            <img src={tags_icon} alt="tags_icon"/>
                            <input type="text" placeholder="Enter tags"/>
                        </div>
                    </div>
                    <div className="ready_delivered_b d_flex align_items_start space_between margin_top_32">
                       <ReadyCollection/>
                        <ToDelivered/>
                    </div>
                    <CollectionDelivered/>
                    <Goods goodsType={goodsType} setGoodType={setGoodsType} valueCurrency={valueCurrency} setValueCurrency={setValueCurrency}/>
                    <IncotermsCustomer code={code} setCode={setCode}/>
                    <DocumentsRemarks documentsCategory={documentsCategory} setDocumentsCategory={setDocumentsCategory}/>
                    <div className="d_flex justify_content_end next_btn_b" onClick={()=>navigate("/dashboard/shipments/new_shipment/transport_book")}>
                        <button className="next_btn bc_green fs_18 f_600">Next</button>
                    </div>
                </div>
            </div>


        </div>
    )


}
