import "./style.css";
import logo from "../../../assets/images/Logo (2).png";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import Header from "../../../components/header/header";
import SettingsComponent from "./components/settings_component/SettingsComponent";
import NotificationSettings from "./components/notification_settings/NotificationSettings";

export default function Settings() {
    let navigate = useNavigate()
    let [error, setError] = useState("")
    let [showSettings, setShowSettings] = useState(true);
    let [showCompanySettings, setShowCompanySettings] = useState(false);
    let [showNotificationSettings, setShowNotificationSettings] = useState(false);

    let tabToggler = (active) => {
        if (active === "Settings"){
            setShowSettings(true);
            setShowCompanySettings(false);
            setShowNotificationSettings(false)
        }
        if (active === "Company settings"){
            setShowSettings(false);
            setShowCompanySettings(true);
            setShowNotificationSettings(false)
        }
        if (active === "Notification settings"){
            setShowSettings(false);
            setShowCompanySettings(false);
            setShowNotificationSettings(true)
        }
    }



    return (
        <div className="d_flex fd_column">
            <Header/>
            {/*<div className="go_back_header d_flex space_between align_items_start">*/}
            {/*    <div className="d_flex align_items_center fs_18 f_600">*/}

            {/*    </div>*/}
            {/*    <div onClick={logOutUser}>*/}
            {/*        <img src={dashboard_header_icon} alt="logout_b"/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="settings_container d_flex fd_column justify_content_start">
                <h2 className="f_700 text_left">Settings</h2>
                <div className="d_flex tabs_block margin_top_32">
                    <button  className={showSettings ? "tab_active": "tab"}
                             onClick={()=>tabToggler("Settings")}
                    >Settings
                    </button>
                    <button  className={showCompanySettings ? "tab_active": "tab"}
                             onClick={()=>tabToggler("Company settings")}>
                        Company Settings
                    </button>
                    <button  className={showNotificationSettings ? "tab_active": "tab"}
                             onClick={()=>tabToggler("Notification settings")}>
                        Notification Settings
                    </button>
                </div>
                <div id="Settings" className={showSettings ? "showTab": "hideTab"}>
                 <SettingsComponent/>
                </div>
                <div id="Company settings" className={showCompanySettings ? "dhowTab" : "hideTab"}>
                   <div className="settings_content_container">
                       fdfdf
                   </div>
                </div>
                <div id="Notification settings" className={showNotificationSettings ? "dhowTab" : "hideTab"}>
                 <NotificationSettings/>
                </div>
            </div>
        </div>
    )
}
