import "./style.css";
import logo from "../../../assets/images/Logo (2).png";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import arrow_left from "../../../assets/images/icons/arrow_left.png";
import dashboard_header_icon from "../../../assets/images/icons/dashboard_header_icon.png";
import axios from "axios";
import config from "../../../config";
import Header from "../../../components/header/header";

export default function Shipments() {
    let navigate = useNavigate()
    let [error, setError] = useState("")
    let token = localStorage.getItem("jwtToken")
    let [company, setCompany] = useState([
        {
            id: 1,
            img: logo,
            name: "Lorem ipsum dolor amet",
            desc: "Lorem ipsum dolor sit amet consectetur. Pellentesque eros libero nisl imperdiet urna."
        },
        {
            id: 2,
            img: logo,
            name: "Lorem ipsum dolor amet",
            desc: "Lorem ipsum dolor sit amet consectetur. Pellentesque eros libero nisl imperdiet urna."
        },
    ])
    let handleNavigateShipment = (id) => {
        navigate(`/dashboard/shipments/details/${id}`, {state: {id}})

    }

    return (
        <div className="d_flex fd_column">
            <Header token={token}/>
            {/*<div className="go_back_header d_flex space_between align_items_start">*/}
            {/*    <div className="d_flex align_items_center fs_18 f_600">*/}

            {/*    </div>*/}
            {/*    <div onClick={logOutUser}>*/}
            {/*        <img src={dashboard_header_icon} alt="logout_b"/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="shipments_cont d_flex fd_column justify_content_start">
                <h2 className="f_700 text_left">My companies</h2>
                <p className="fs_16 text_left">Choose your company</p>
                <div className="margin_top_32 shipments_cont_content d_flex fd_column justify_content_start">
                    {
                        company.map((item, index) => {
                            return (
                                <div className="shipments_cont_content_item d_flex align_items_center" key={index}
                                     onClick={() => handleNavigateShipment(item.id)}>
                                    <div>
                                        <img src={item.img} alt="logo"/>
                                    </div>
                                    <div className="d_flex fd_column justify_content_start
                                align_items_start shipments_cont_content_item_textB">
                                        <p className="fs_20 f_700">{item.name}</p>
                                        <div className="fs_14">{item.desc}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
