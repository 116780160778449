import "./style.css";
import {useState} from "react";
import Header from "../../../components/header/header";
import plus_icon from "../../../assets/images/icons/plus_icon.png";
import search_icon from "../../../assets/images/icons/search_icon.png";
import company_logo from "../../../assets/images/company_logo_shipment.png";
import delete_icon from "../../../assets/images/icons/delete_black_icon.png";
import checked_icon from "../../../assets/images/icons/checkmark_icon.png";
import {useNavigate} from "react-router-dom";

export default function MyCarriers() {
    let navigate = useNavigate()
    let [carriers, setCarriers] = useState([
        {
            id: 1,
            img: company_logo,
            companyName: "DPD",
            regNo: "1024496",
            VAT: "EE100448261",
            description: "Lorem ipsum dolor",
            integration: "Integrated",
        },
        {
            id: 2,
            img: company_logo,
            companyName: "DPD",
            regNo: "10144906",
            VAT: "EE1004461",
            description: "Lorem dolor",
            integration: "E-mail Integration",
        },
        {
            id: 3,
            img: company_logo,
            companyName: "DPD",
            regNo: "10144906",
            VAT: "EE100448261",
            description: "Lorem dolor",
            integration: "Integrated",
        },
        {
            id: 4,
            img: company_logo,
            companyName: "DPD",
            regNo: "10144906",
            VAT: "EE1448261",
            description: "Lorem ipsum dolor",
            integration: "E-mail Integration",
        },
        {
            id: 5,
            img: company_logo,
            companyName: "DPD",
            regNo: "10144906",
            VAT: "EE100441",
            description: "Lorem ipsum dolor",
            integration: "Integrated",
        },
        {
            id: 6,
            img: company_logo,
            companyName: "DPD",
            regNo: "1044906",
            VAT: "EE100448261",
            description: "Ipsum dolor",
            integration: "E-mail Integration",
        },
    ])
    let [filteredCarriers, setFilteredCarriers]=useState([])
    let [searchText, setSearchText] = useState("")

    let handleClickDelete = (index) => {
        carriers.splice(carriers[index], 1)
        setCarriers([...carriers])
    }

    let handleChangeSearch = (e) => {
        setSearchText(e.target.value)

    }

    let Search=()=>{
        filteredCarriers = carriers.filter((item) => {
            if(item.VAT===searchText){
                return item
            }
            else{
                return  false
            }
        })

        return (
            <div>
                {
                   filteredCarriers.map((item,index)=>{
                        return (
                            <div
                                className="carriers_content_item d_flex space_between align_items_center"
                                key={item.id}>
                                <div className="d_flex align_items_center">
                                    <img src={item.img} alt="logo"
                                         className="carriers_content_item_logo"/>
                                    <div className="d_flex fd_column justify_content_start text_left">
                                        <p className="f_600 fs_16">{item.companyName}</p>
                                        <div
                                            className="d_flex align_items_center carriers_content_item_reg">
                                            <div className="fs_14">Reg no: {item.regNo}</div>
                                            <span></span>
                                            <div className="fs_14">VAT: {item.VAT}</div>
                                        </div>
                                        <div className="fs_14">{item.description}</div>
                                        <button
                                            className="d_flex align_items_center justify_content_center">
                                            <img src={checked_icon} alt="ok"/>{item.integration}
                                        </button>
                                    </div>
                                </div>
                                <div onClick={() => handleClickDelete(index)}>
                                    <img src={delete_icon} alt="delete"/>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            Search()
        }
    };


    return (
        <div className="d_flex fd_column">
            <Header/>
            {/*<div className="go_back_header d_flex space_between align_items_start">*/}
            {/*    <div className="d_flex align_items_center fs_18 f_600">*/}

            {/*    </div>*/}
            {/*    <div onClick={logOutUser}>*/}
            {/*        <img src={dashboard_header_icon} alt="logout_b"/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="carriers_container d_flex fd_column justify_content_start">
                <div className="d_flex space_between carriers_title_b">
                    <h2 className="f_700 text_left">My carriers</h2>
                    <button className="bc_green fs_18 f_600" style={{maxWidth: "170px"}}
                            onClick={() => navigate("/dashboard/add_carrier")}><img src={plus_icon}
                                                                                    alt="plus"/> Add carrier
                    </button>
                </div>
                <div className="text_left margin_top_16">You can activate <span
                    className="f_700">new carriers</span> using the buttoon above.
                </div>
                <div className="text_left"> Click on a carrier to add <span
                    className="f_700">pricelists</span> and <span className="f_700">integrations,</span> or edit
                    services.
                </div>
                <div className="margin_top_48 search_block">
                    <img src={search_icon} alt="search"/>
                    <input type="text" placeholder="Search" onChange={(e) => handleChangeSearch(e)}
                           onKeyDown={handleKeyDown}/>
                </div>
                {
                    filteredCarriers.length ?
                        <div className="text_left margin_top_8">
                            Displaying <span className="f_700">all {filteredCarriers.length}</span> companies
                        </div>
                        :
                        <div className="text_left margin_top_8">
                            Displaying <span className="f_700">all {carriers.length}</span> companies
                        </div>
                }


                <div className="d_flex fd_column margin_top_24 carriers_content_block">
                    {
                        searchText ?
                            // <div>
                            //     {
                            //        filteredCarriers.map((item,index)=>{
                            //             return (
                            //                 <div
                            //                     className="carriers_content_item d_flex space_between align_items_center"
                            //                     key={item.id}>
                            //                     <div className="d_flex align_items_center">
                            //                         <img src={item.img} alt="logo"
                            //                              className="carriers_content_item_logo"/>
                            //                         <div className="d_flex fd_column justify_content_start text_left">
                            //                             <p className="f_600 fs_16">{item.companyName}</p>
                            //                             <div
                            //                                 className="d_flex align_items_center carriers_content_item_reg">
                            //                                 <div className="fs_14">Reg no: {item.regNo}</div>
                            //                                 <span></span>
                            //                                 <div className="fs_14">VAT: {item.VAT}</div>
                            //                             </div>
                            //                             <div className="fs_14">{item.description}</div>
                            //                             <button
                            //                                 className="d_flex align_items_center justify_content_center">
                            //                                 <img src={checked_icon} alt="ok"/>{item.integration}
                            //                             </button>
                            //                         </div>
                            //                     </div>
                            //                     <div onClick={() => handleClickDelete(index)}>
                            //                         <img src={delete_icon} alt="delete"/>
                            //                     </div>
                            //                 </div>
                            //             )
                            //         })
                            //     }
                            // </div>
                            <Search/>
                            :
                            <div>
                                {
                                    carriers.map((item, index) => {
                                        return (
                                            <div
                                                className="carriers_content_item d_flex space_between align_items_center"
                                                key={item.id}>
                                                <div className="d_flex align_items_center">
                                                    <img src={item.img} alt="logo"
                                                         className="carriers_content_item_logo"/>
                                                    <div className="d_flex fd_column justify_content_start text_left">
                                                        <p className="f_600 fs_16">{item.companyName}</p>
                                                        <div
                                                            className="d_flex align_items_center carriers_content_item_reg">
                                                            <div className="fs_14">Reg no: {item.regNo}</div>
                                                            <span></span>
                                                            <div className="fs_14">VAT: {item.VAT}</div>
                                                        </div>
                                                        <div className="fs_14">{item.description}</div>
                                                        <button
                                                            className="d_flex align_items_center justify_content_center">
                                                            <img src={checked_icon} alt="ok"/>{item.integration}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div onClick={() => handleClickDelete(index)}>
                                                    <img src={delete_icon} alt="delete"/>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }

                </div>

            </div>
        </div>
    )
}