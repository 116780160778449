import "../style.css";
import star_white_pic from "../../../assets/images/star_white_pic.png";
import ok_icon from "../../../assets/images/icons/ok_icon _green.png";

export default function PricingOffers({pricing,setPricing}){
    return (
        <div className="pricing_cont">
            <div className="container d_flex fd_column">
                <h2 className="text_center c_green fs_40 f_700">Pricing</h2>
                <div className="fs_18 c_white margin_top_16 f_400 text_center price_desc">
                    Lorem ipsum dolor sit amet consectetur. Tellus velit sit ornare habitant adipiscing quis
                    feugiat in.
                </div>
                <div className="pricing_items_cont d_grid grid_columns_4fr grid_gab_32">
                    {pricing.map((item, index) => {
                        return (
                            <div className="pricing_item d_flex fd_column" key={item.id}>
                                <div className="d_flex space_between align_items_center">
                                    <img src={item.img} alt=""/>
                                    {item.offer &&
                                    <div className="d_flex align_items_center offer_b">
                                        <img src={star_white_pic} alt="star" className="star_white_pic"/>
                                        <p className="c_white fs_14 f_600">{item.offer}</p>
                                    </div>
                                    }
                                </div>
                                <h3 className="fs_24 f_70 c_white text_left margin_top_24">{item.name}</h3>
                                <p className="text_left fs_18 c_white margin_top_12">For eCommerce and
                                    retail</p>
                                <div className="c_green fs_40 text_left margin_top_16">${item.price}<span
                                    className="c_white fs_18 f_400"
                                    style={{marginLeft: "10px"}}>/Per month</span></div>
                                <div className="features_block margin_top_24">
                                    <p className="margin_top_24 fs_20 f_600 c_white text_left">Top features</p>
                                    <div className="margin_top_16 text_left">
                                        {
                                            item.advantage1 &&
                                            <div className="d_flex c_white fs_18 margin_top_16 align_items_start">
                                                <img src={ok_icon} alt="ok" className="ok_icon" style={{marginRight:"10px"}}/>
                                                {item.advantage1}
                                            </div>
                                        }
                                        {
                                            item.advantage2 &&
                                            <div className="d_flex c_white fs_18 align_items_start margin_top_16">
                                                <img src={ok_icon} alt="ok" className="ok_icon" style={{marginRight:"10px"}}/>
                                                {item.advantage2}
                                            </div>
                                        }
                                        {
                                            item.advantage3 &&
                                            <div className="d_flex c_white fs_18 align_items_start margin_top_16">
                                                <img src={ok_icon} alt="ok" className="ok_icon" style={{marginRight:"10px"}}/>
                                                {item.advantage3}
                                            </div>
                                        }
                                        {
                                            item.advantage4 &&
                                            <div className="d_flex c_white fs_18 align_items_start margin_top_16">
                                                <img src={ok_icon} alt="ok" className="ok_icon" style={{marginRight:"10px"}}/>
                                                {item.advantage4}
                                            </div>
                                        }
                                        {
                                            item.advantage5 &&
                                            <div className="d_flex c_white fs_18 align_items_start margin_top_16">
                                                <img src={ok_icon} alt="ok" className="ok_icon" style={{marginRight:"10px"}}/>
                                                {item.advantage5}
                                            </div>
                                        }
                                        {
                                            item.advantage6 &&
                                            <div className="d_flex c_white fs_18 align_items_start margin_top_16">
                                                <img src={ok_icon} alt="ok" className="ok_icon" style={{marginRight:"10px"}}/>
                                                {item.advantage6}
                                            </div>
                                        }
                                        {
                                            item.advantage7 &&
                                            <div className="d_flex c_white fs_18 align_items_start margin_top_16">
                                                <img src={ok_icon} alt="ok" className="ok_icon" style={{marginRight:"10px"}}/>
                                                {item.advantage7}
                                            </div>
                                        }
                                        {
                                            item.advantage8 &&
                                            <div className="d_flex c_white fs_18 align_items_start margin_top_16">
                                                <img src={ok_icon} alt="ok" className="ok_icon" style={{marginRight:"10px"}}/>
                                                {item.advantage8}
                                            </div>
                                        }
                                    </div>
                                    <button className="get_started_btn margin_top_32 f_600">Get Started</button>
                                </div>

                            </div>
                        )
                    })

                    }
                </div>
            </div>
        </div>
    )
}