import "./style.css";
import Header from "../../components/header/header";
import tracking_pic from "../../assets/images/tracking_pic.png";
import Footer from "../../components/footer/footer";

export default function Tracking() {
    return (
        <div className="main">
            <div>
                <Header/>
                <div className="tracking_cont">
                    <div className="container d_flex align_items_center justify_content_center space_between">
                        <div className="tracking_cont_b1">
                            <img src={tracking_pic} alt="tracking_pic" className="tracking_pic"/>
                        </div>
                        <div className="tracking_cont_b2 d_flex fd_column justify_content_start text_left">
                            <h2 className="fs_40 c_white f_700">Track your <span
                                className="c_green f_700">shipment</span></h2>
                            <div className="margin_top_16 fs_16 c_white text_left">
                                Lorem ipsum dolor sit amet consectetur. Tellus velit sit ornare habitant adipiscing quis
                                feugiat in. Lorem ipsum dolor sit amet consectetur. Tellus velit sit ornare habitant
                                adipiscing quis feugiat.
                            </div>
                            <div className="margin_top_32 d_flex align_items_center submit_block">
                                <div className="submit_block_input">
                                    <input type="text" placeholder="Code" className="fs_20"/>
                                </div>
                                <div className="submit_btn_b">
                                    <button className="f_600 button_full">Submit</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}