import "./style.css";
import Header from "../../components/header/header";
import sign_up_pic from "../../assets/images/sign_up_pic.svg";
import Footer from "../../components/footer/footer";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import config from "../../config";

export default function ResetPassword() {
    let navigate = useNavigate()
    let [resetPass, setResetPass] = useState({
        password: "",
        passwordConfirmation: "",
    })
    let [resetPassBtn, setResetPassBtn] = useState(false)
    const [error, setError] = useState("")
    const {token}=useParams()
    const handleChangeInput = (e, name) => {
        setResetPass({...resetPass, [name]: e})
        setError("")
    }
    let ResetPassUser = async () => {
        try {
            setResetPassBtn(true)
            if (resetPass.password && resetPass.passwordConfirmation) {
                if (resetPass.password.length >= 8) {
                    if (resetPass.password === resetPass.passwordConfirmation) {
                        let response = await axios.put(`${config.url}/auth/reset-password/${token}`, resetPass)
                        console.log(response, "response");
                        navigate("/sign_in")
                    } else {
                        setError("Passwords are different")
                    }
                } else {
                    setError("Password length is small")
                }
            }
        } catch (e){
            console.log(error.response, "error")
            setError(error.response?.data)
        }
    }
    // useEffect(()=>{
    //     let code= params.get('code');
    //     console.log(code)
    //     ResetPassUser(code)
    // }, []);




    return (
        <div className="main">
            <div>
                <Header/>
                <div className="sign_up_cont">
                    <div className="container d_flex align_items_center space_between sign_up_container">
                        <div className="sign_up_cont_sign_up text_left d_flex fd_column justify_content_start">
                            <h2 className="fs_40 c_white f_700 text_left">Reset Password</h2>
                            <div className="inputs_block margin_top_32">
                                <div className="margin_top_16 input_block"
                                     style={!resetPass.password && resetPassBtn ? {border: "1px solid red"} : null}>
                                    <input type="password" placeholder="New Password" className="fs_18"
                                           onChange={(e) => handleChangeInput(e.target.value, 'password')}
                                    />
                                </div>
                                <div className="margin_top_16 input_block"
                                     style={!resetPass.passwordConfirmation && resetPassBtn ? {border: "1px solid red"} : null}>
                                    <input type="password" placeholder="Confirm Password" className="fs_18"
                                           onChange={(e) => handleChangeInput(e.target.value, 'passwordConfirmation')}
                                    />
                                </div>
                            </div>
                            {error ? <p className="error">{error}</p> : null}
                            <button className="create_account_btn bc_green fs_20 f_600 margin_top_32"
                                    onClick={ResetPassUser}>Confirm
                            </button>
                            <div className="margin_top_16 have_account_b fs_18 text_center">
                                Already have an account?
                                <span className="c_green fs_18 f_600"
                                      onClick={() => navigate("/sign_in")}>Sign in</span>
                            </div>

                        </div>
                        <div className="sign_up_cont_img_b">
                            <img src={sign_up_pic} alt="sign_up_pic" className="sign_up_pic"/>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
