import "./style.css";
import arrow_left from "../../../../../assets/images/icons/arrow_left.png";
import {useLocation, useNavigate} from "react-router-dom";
import plus_icon from "../../../../../assets/images/icons/plus_icon.png";
import search_icon from "../../../../../assets/images/icons/search_icon.png";
import calendar_icon from "../../../../../assets/images/icons/calendar_icon.png";
import arrow_left_2 from "../../../../../assets/images/icons/arrow_left_2.png";
import address_icon from "../../../../../assets/images/icons/address_icon_black.png";
import delivery_icon from "../../../../../assets/images/icons/delivery_icon.png";
import filtration_icon from "../../../../../assets/images/icons/filtration_icon.png";
import print_black from "../../../../../assets/images/icons/print_black_icon.png";
import ShipmentItems from "../shipment_items/ShipmentItems";
import CollectionCalendar from "../collection_calendar/CollectionCalendar";
import {useState} from "react";
import dashboard_header_icon from "../../../../../assets/images/icons/dashboard_header_icon.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from "../../../../../components/header/header";

export default function ShipmentDetails() {
    const [collectionDate, setCollectionDate] = useState(new Date());

    let resetFilters =()=>{
        setCollectionDate(new Date())
    }
let newShipment = ()=>{
    navigate(`/dashboard/shipments/new_shipment`)
}
    let navigate = useNavigate()
    return (
        <div className="d_flex fd_column">
            <Header/>
            <div className="go_back_header_btn_b d_flex space_between align_items_start">
                <div className="d_flex align_items_center fs_18 f_600"
                     onClick={() => navigate("../../dashboard/shipments")}>
                    <img src={arrow_left} alt="arrow_left"/> Go Back
                </div>
                {/*<div>*/}
                {/*    <img src={dashboard_header_icon} alt="logout_b"/>*/}
                {/*</div>*/}
            </div>
            <div className="shipments_cont d_flex fd_column justify_content_start">
                <div className="shipments_content_cont">
                    <div className="title_b d_flex align_items_center space_between">
                        <h2 className="">Shipments</h2>
                        <div className="new_shipment_btn_b" onClick={newShipment}>
                            <button className="d_flex align_items_center justify_content_center fs_18 f_600"><img
                                src={plus_icon} alt="plus_icon"/>New Shipment
                            </button>
                        </div>
                    </div>
                    <div className="search_block_cont d_flex align_items_center space_between margin_top_48">
                        <div className="search_b">
                            <input type="text" placeholder="Search" className="fs_18"/>
                            <img src={search_icon} alt="search_icon"/>
                        </div>
                        <div className="calendar_b">
                            <div>
                                <CollectionCalendar collectionDate={collectionDate} setCollectionDate={setCollectionDate}/>
                                <img src={calendar_icon} alt="calendar_icon"/>
                            </div>
                        </div>
                        <div className="address_to d_flex justify_content_center align_items_center">
                            <img src={address_icon} alt="address_icon" className="address_icon"/>
                            <img src={arrow_left_2} alt="calendar_icon" className="arrow_left"/>
                        </div>
                        <div className="delivery_b">
                            <input type="text" placeholder="Delivery"  className="fs_18"/>
                            <img src={delivery_icon} alt="delivery_icon"/>
                        </div>
                        <div className="address_from d_flex align_items_center justify_content_center">
                            <img src={address_icon} alt="address_icon" className="address_icon"/>
                            <img src={arrow_left_2} alt="calendar_icon" style={{transform:"rotate(180deg)"}} className="arrow_right"/>
                        </div>
                        <button className="d_flex align_items_center justify_content_center filtration_btn fs_18 f_600"><img src={filtration_icon} alt=""/>Filtration</button>
                    </div>

                </div>
                <div className="d_flex space_between align_items_center reset_filters_b margin_top_12">
                    <div className='fs_16'>Displaying <span className="f_600"> all 3</span> queries</div>
                    <div className="d_flex align_items_center reset_filter_btn fs_14" onClick={resetFilters}>Reset filters <img src={print_black} alt=""/></div>
                </div>
                <ShipmentItems/>
            </div>



        </div>
    )
}
