import "./style.css";
import Header from "../../components/header/header";
import sign_up_pic from "../../assets/images/sign_up_pic.svg";
import Footer from "../../components/footer/footer";
import {Outlet, useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import config from "../../config";

export default function ForgotPassword() {
    let navigate = useNavigate()
    let [forgotPass, setForgotPass] = useState({email: ""})
    let [forgotPassBtn, setForgotPassBtn] = useState(false)
    const [error, setError] = useState("")
    let token = localStorage.getItem('jwtToken') ? localStorage.getItem('jwtToken') : sessionStorage.getItem('jwtToken')

    const handleChangeInput = (e, name) => {
        setForgotPass({...forgotPass, [name]: e})
        setError("")
    }
    let validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(forgotPass.email).toLowerCase());
    }
    let ForgotPassUser = async () => {
        try {
            setForgotPassBtn(true)
            if (forgotPass.email) {
                if (validateEmail()) {
                    let response = await axios.post(`${config.url}/auth/forgot-password`, forgotPass )
                    console.log(response, "response");
                    console.log(response.data, "response");
                    console.log(token, "token");
                    navigate(`/email_send`,{state:{forgotPass}})
                } else {
                    setError("Incorrect email address")
                }
            }
        } catch (e) {
            console.log(error.response, "error")
            setError(error.response?.data)
        }
    }


    return (
        <div className="main">
            <div>
                <Header/>
                        <div className="sign_up_cont forget_pass_cont">
                            <div className="container d_flex align_items_center space_between sign_up_container">
                                <div className="sign_up_cont_sign_up text_left d_flex fd_column justify_content_start">
                                    <h2 className="fs_40 c_white f_700 text_left">Forgot Password?</h2>
                                    <div className="inputs_block margin_top_32">
                                        <div className="margin_top_16 input_block"
                                             style={!forgotPass.email && forgotPassBtn ? {border: "1px solid red"} : null}>
                                            <input type="email" placeholder="Email" className="fs_18"
                                                   onChange={(e) => handleChangeInput(e.target.value, 'email')}
                                            />
                                        </div>
                                    </div>
                                    {error ? <p className="error">{error}</p> : null}
                                    <button className="create_account_btn bc_green fs_20 f_600 margin_top_32"
                                            onClick={ForgotPassUser}>Send
                                    </button>
                                    <div className="margin_top_16 have_account_b fs_18 text_center">
                                        Already have an account?
                                        <span className="c_green fs_18 f_600"
                                              onClick={() => navigate("/sign_in")}>Sign in</span>
                                    </div>

                                </div>
                                <div className="sign_up_cont_img_b">
                                    <img src={sign_up_pic} alt="sign_up_pic" className="sign_up_pic"/>
                                </div>
                            </div>
                        </div>

            </div>
            <Footer/>
        </div>
    )
}
