import "./style.css";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {useState} from "react";
import retail_pricing_icon from "../../assets/images/icons/retail_pricing_icon.png";
import industry_pricing_icon from "../../assets/images/icons/industry_pricing_icon.png";
import corporation_pricing_icon from "../../assets/images/icons/corporation_pricing_icon.png";
import custom_pricing_icon from "../../assets/images/icons/custom_pricing_icon.png";
import PricingOffers from "./pricing_offers/PricingOffers";
import PricingOffersDesc from "./pricing_offers_desc/PricingOffersDesc";

export default function Pricing() {
    let [pricing, setPricing] = useState([
        {
            id: 1,
            img: retail_pricing_icon,
            offer: "Best offer",
            name: "Retail",
            price: "99",
            advantage1: "Lorem ipsum dolor",
            advantage2: "Tellus velit sit ornare habitant",
            advantage3: "Lorem ipsum dolor",
            advantage4: "Tellus velit sit ornare",
            advantage5: "Lorem ipsum dolor",
            advantage6: "Tellus velit sit ornare",
            advantage7: "",
            advantage8: "",
        },
        {
            id: 2,
            img: industry_pricing_icon,
            offer: "",
            name: "Industry",
            price: "99",
            advantage1: "Lorem ipsum dolor",
            advantage2: "Tellus velit sit ornare habitant",
            advantage3: "Lorem ipsum dolor",
            advantage4: "Tellus velit sit ornare",
            advantage5: "Lorem ipsum dolor",
            advantage6: "Tellus velit sit ornare",
            advantage7: "Tellus velit sit ornare",
            advantage8: "Tellus velit sit ornare",
        },
        {
            id: 3,
            img: corporation_pricing_icon,
            offer: "",
            name: "Corporation",
            price: "99",
            advantage1: "Lorem ipsum dolor",
            advantage2: "Tellus velit sit ornare habitant",
            advantage3: "Lorem ipsum dolor",
            advantage4: "Tellus velit sit ornare",
            advantage5: "Lorem ipsum dolor",
            advantage6: "Tellus velit sit ornare",
            advantage7: "",
            advantage8: "",
        },
        {
            id: 4,
            img: custom_pricing_icon,
            offer: "",
            name: "Custom",
            price: "99",
            advantage1: "Lorem ipsum dolor",
            advantage2: "Tellus velit sit ornare habitant",
            advantage3: "Lorem ipsum dolor",
            advantage4: "Tellus velit sit ornare",
            advantage5: "Lorem ipsum dolor",
            advantage6: "Tellus velit sit ornare",
            advantage7: "Tellus velit sit ornare",
            advantage8: "Tellus velit sit ornare",
        },
    ])
    return (
        <div className="main">
            <div>
                <Header/>
               <PricingOffers pricing={pricing} setPricing={setPricing}/>
                <PricingOffersDesc/>
            </div>
            <Footer/>
        </div>
    )
}