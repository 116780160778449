import "./style.css";
import warehouse_pic from "../../../../assets/images/warehouse_pic.svg";
import couriers_pic from "../../../../assets/images/couriers_pic.svg";
import pick_up_pic from "../../../../assets/images/pick_up_pic.svg";


export default function HomeInfoBlock() {
    return (
        <div className="home_info_block_cont d_flex fd_column align_items_center">
            <div className="d_flex align_items_center warehouse_cont">
                <div className="warehouse_cont_block1">
                    <img src={warehouse_pic} alt="warehouse_pic" className="warehouse_pic"/>
                </div>
                <div className="warehouse_cont_block2">
                    <h2 className="fs_40 f_700 text_left">Warehouses</h2>
                    <div className="fs_18 text_left margin_top_16">
                        Lorem ipsum dolor sit amet consectetur. Fames varius at eleifend leo adipiscing odio congue.
                        Pretium lacinia quam consectetur ac dolor vitae enim at. Eget convallis maecenas integer purus
                        tellus mattis ut. Nulla egestas id commodo neque at. Posuere amet massa donec nunc.
                    </div>
                    <div className="fs_18 text_left margin_top_24">
                        Ac cras pellentesque sit rhoncus duis consectetur pretium gravida. Laoreet eget nisl eros
                        condimentum accumsan ac odio. Vestibulum quis quam arcu aliquam penatibus sit nisl proin
                        egestas. Euismod sagittis porttitor ullamcorper placerat quisque porttitor.
                    </div>
                    <div className="d_flex justify_content_start margin_top_32">
                        <button className="button_full">See more</button>
                    </div>
                </div>
            </div>
            <div className="couriers_cont d_flex align_items_center">
                <div className="warehouse_cont_block1">
                    <h2 className="fs_40 f_700 text_left">Couriers Companies</h2>
                    <div className="fs_18 text_left margin_top_16">
                        Lorem ipsum dolor sit amet consectetur. Fames varius at eleifend leo adipiscing odio congue.
                        Pretium lacinia quam consectetur ac dolor vitae enim at. Eget convallis maecenas integer purus
                        tellus mattis ut. Nulla egestas id commodo neque at. Posuere amet massa donec nunc.
                    </div>
                    <div className="fs_18 text_left margin_top_24">
                        Ac cras pellentesque sit rhoncus duis consectetur pretium gravida. Laoreet eget nisl eros
                        condimentum accumsan ac odio. Vestibulum quis quam arcu aliquam penatibus sit nisl proin
                        egestas. Euismod sagittis porttitor ullamcorper placerat quisque porttitor.
                    </div>
                    <div className="d_flex justify_content_start margin_top_32">
                        <button className="button_full">See more</button>
                    </div>
                </div>
                <div className="warehouse_cont_block2">
                    <img src={couriers_pic} alt="couriers_pic" className="couriers_pic"/>
                </div>
            </div>
            <div className="pick_up_cont d_flex align_items_center">
                <div className="warehouse_cont_block1">
                    <img src={pick_up_pic} alt="pick_up_pic" className="pick_up_pic"/>
                </div>
                <div className="warehouse_cont_block2">
                    <h2 className="fs_40 f_700 text_left">Pick up </h2>
                    <div className="fs_18 text_left margin_top_16">
                        Lorem ipsum dolor sit amet consectetur. Fames varius at eleifend leo adipiscing odio congue.
                        Pretium lacinia quam consectetur ac dolor vitae enim at. Eget convallis maecenas integer purus
                        tellus mattis ut. Nulla egestas id commodo neque at. Posuere amet massa donec nunc.
                    </div>
                    <div className="fs_18 text_left margin_top_24">
                        Ac cras pellentesque sit rhoncus duis consectetur pretium gravida. Laoreet eget nisl eros
                        condimentum accumsan ac odio. Vestibulum quis quam arcu aliquam penatibus sit nisl proin
                        egestas. Euismod sagittis porttitor ullamcorper placerat quisque porttitor.
                    </div>
                    <div className="d_flex justify_content_start margin_top_32">
                        <button className="button_full">See more</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
