import "./style.css";
import {useState} from "react";
import yes_icon from "../../../assets/images/icons/yes_icon.png";
import no_icon from "../../../assets/images/icons/tabler_minus.png";

export default function PricingOffersDesc() {
    let [features, setFeatures] = useState([
        {
            id: 1,
            title: "Features",
            title1: "",
            title2: "Lorem ipsum dolor sit amet consectetur. Tellus velit sit ornare habitant adipiscing quis feugiat in. ",
            td1: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td2: "Shipment dashboard",
            td3: "Your company's personal address book",
            td4: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td5: "Shipment dashboard",
            td6: "Your company's personal address book",
        },
    ])
    let [featuresContent, setFeaturesContent] = useState([

        {
            id: 1,
            title: "Retail",
            title1: "For eCommerce and retail",
            title2: "$99",
            td1: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td2: "Shipment dashboard",
            td3: "Your company's personal address book",
            td4: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td5: "Shipment dashboard",
            td6: "Your company's personal address book",
        },
        {
            id: 2,
            title: "Retail",
            title1: "For eCommerce and retail",
            title2: "$99",
            td1: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td2: "",
            td3: "Your company's personal address book",
            td4: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td5: "Shipment dashboard",
            td6: "Your company's personal address book",
        },
        {
            id: 3,
            title: "Retail",
            title1: "For eCommerce and retail",
            title2: "$99",
            td1: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td2: "",
            td3: "Your company's personal address book",
            td4: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td5: "",
            td6: "Your company's personal address book",
        },
        {
            id: 4,
            title: "Retail",
            title1: "For eCommerce and retail",
            title2: "$99",
            td1: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td2: "",
            td3: "Your company's personal address book",
            td4: "Road/Courier/Parcel Locker/Air/Sea/Rail",
            td5: "Shipment dashboard",
            td6: "",
        },

    ])
    return (
        <div className="pricing_offers_desc_cont">
            <div className="container">
                <div className="d_flex align_items_start">
                    <div className="d_flex fd_column justify_content_start">
                        {
                            features.map((item, index) => {
                                return (
                                    <div className="d_flex fd_column text_left pricing_offers_desc_cont_b1">
                                        <div className="fs_40 f_700">{item.title}</div>
                                        <div>{item.title1}</div>
                                        <div className="margin_top_16 fs_18">{item.title2}</div>
                                        <div className="pricing_offers_desc_cont_b1_items">
                                            <div className="text_left fs_18 f_600">{item.td1}</div>
                                            <div className="text_left fs_18 f_600">{item.td2}</div>
                                            <div className="text_left fs_18 f_600">{item.td3}</div>
                                            <div className="text_left fs_18 f_600">{item.td4}</div>
                                            <div className="text_left fs_18 f_600">{item.td5}</div>
                                            <div className="text_left fs_18 f_600">{item.td6}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="d_flex space_between pricing_offers_desc_cont_b2">
                        {
                            featuresContent.map((item, index) => {
                                return (
                                    <div className="d_flex fd_column  pricing_offers_desc_cont_b2_item">
                                        <h2 className="fs_24 f_700 text_left">{item.title}</h2>
                                        <p className="text_left margin_top_12">{item.title1}</p>
                                        <p className="text_left margin_top_16 f_600"><span className="c_green fs_40 f_700" style={{marginRight:"10px"}}>{item.title2}</span>/ Per month </p>
                                        <div className="pricing_offers_desc_cont_b2_item_content">
                                            <div><img src={item.td1 ? yes_icon : no_icon } alt=""/></div>
                                            <div><img src={item.td2 ? yes_icon : no_icon } alt=""/></div>
                                            <div><img src={item.td3 ? yes_icon : no_icon } alt=""/></div>
                                            <div><img src={item.td4 ? yes_icon : no_icon } alt=""/></div>
                                            <div><img src={item.td5 ? yes_icon : no_icon } alt=""/></div>
                                            <div><img src={item.td6 ? yes_icon : no_icon } alt=""/></div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}