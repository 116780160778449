import React, { useState } from "react";
import "../../style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function ReactDatepicker() {
    const [basicDate, setBasicDate] = useState(new Date());

    const [dateTime, setDateTime] = useState(new Date());

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleSubmit = (e) => {
        e.preventDefault();
        // Sample postBody request
        const postBody = {
            basicDate: basicDate,
            dateTime: dateTime,
            startDate: startDate,
            endDate: endDate
        }

        console.log(postBody);
        alert("Open your devtools - console tab");
    }

    return (
        <div>

            <form method="post" onSubmit={handleSubmit}>
                <div className="section">
                    <div className="section-content">
                        <div className="d_flex margin_top_8 date_input_b_items">
                            <div className="col-md-4 new_shipment_input_block" style={{marginRight:"12px"}}>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </div>
                            <div className="new_shipment_input_block">
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="section">*/}
                {/*    <button className="btn btn-submit">Submit Form</button>*/}
                {/*</div>*/}
            </form>
        </div>
    );
}