import "../../style.css";

export default function AdditionalServices(){
    return (
        <div className="additional_services_block d_flex">
            <div className="d_flex additional_services_block1">
                <div className="d_flex">
                    <div className="d_flex d_flex justify_content_start align_items_center checkbox_block">
                        <input type="checkbox"/>
                        <label htmlFor="" className="fs_14">Tail lift</label>
                    </div>
                    <div className="d_flex d_flex justify_content_start checkbox_block align_items_center">
                        <input type="checkbox"/>
                        <label htmlFor="" className="fs_14">Notify by phone</label>
                    </div>
                </div>
            </div>
            <div className="d_flex additional_services_block2 ">
                <div className="d_flex justify_content_start checkbox_block align_items_center">
                    <input type="checkbox"/>
                    <label htmlFor="" className="fs_14">Tail lift</label>
                </div>
                <div className="d_flex d_flex justify_content_start checkbox_block align_items_center">
                    <input type="checkbox"/>
                    <label htmlFor="" className="fs_14">Notify by phone</label>
                </div>
                <div className="d_flex d_flex justify_content_start checkbox_block align_items_center">
                    <input type="checkbox"/>
                    <label htmlFor="" className="fs_14">Return doc.</label>
                </div>
            </div>
        </div>
    )
}