import "../../style.css";
import {useState} from "react";
import Dropdown from "../../../../../components/dropdown/Dropdown";

export default function NotificationSettings() {
    const [recipientData, setRecipientData] = useState([
        {
            name: 'Author',
            key: 'author',
            data: [
                {
                    id: 1,
                    name: "Author",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Author 2",
                    checked: false,
                },
            ]
        },
        {
            name: 'Collection contact',
            key: 'collection contact',
            data: [
                {
                    id: 1,
                    name: "Collection contact",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Collection contact 2",
                    checked: false,
                },
            ]
        },
        {
            name: 'Delivery contact',
            key: 'delivery contact',
            data: [
                {
                    id: 1,
                    name: "Delivery contact",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Delivery contact 2",
                    checked: false,
                },
            ]
        },
        {
            name: '',
            key: '',
            data: [
                {
                    id: 1,
                    name: "Author",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Collection contact",
                    checked: false,
                },
                {
                    id: 3,
                    name: "Delivery contact",
                    checked: false,
                },
                {
                    id: 4,
                    name: "External e-mail",
                    checked: false,
                },
            ]
        },
    ])
    const [notificationData, setNotificationData] = useState([
        {
            name: 'Booking',
            key: 'booking',
            data: [
                {
                    id: 1,
                    name: "Booking",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Booking 2",
                    checked: false,
                },
            ]
        },
        {
            name: 'Labels',
            key: 'labels',
            data: [
                {
                    id: 1,
                    name: "Labels",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Labels 2",
                    checked: false,
                },
            ]
        },
        {
            name: 'Truck licence plate update',
            key: 'Truck',
            data: [
                {
                    id: 1,
                    name: "Truck licence plate update",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Truck licence plate update 2",
                    checked: false,
                },
            ]
        },
        {
            name: '',
            key: '',
            data: [
                {
                    id: 1,
                    name: "Booking",
                    checked: false,
                },
                {
                    id: 2,
                    name: "Labels",
                    checked: false,
                },
                {
                    id: 3,
                    name: "ETA update",
                    checked: false,
                },
                {
                    id: 4,
                    name: "Truck licence plate update",
                    checked: false,
                },
            ]
        },
    ])
    let [data, setData] = useState([
        {
            recipient: recipientData,
            notification: notificationData,
            import: true,
            export: true,
            btnName: "Delete",
        },
        {
            recipient: recipientData,
            notification: notificationData,
            import: true,
            export: false,
            btnName: "Delete",
        },
        {
            recipient: recipientData,
            notification: notificationData,
            import: true,
            export: true,
            btnName: "Delete",
        },
        {
            recipient: recipientData,
            notification: notificationData,
            import: false,
            export: false,
            btnName: "New",
        },
    ])
    let handleClickBtn = (item, index) => {
        if (item.btnName === "Delete") {
            recipientData.splice(recipientData[index], 1)
            notificationData.splice(notificationData[index], 1)
            data.splice(data[index], 1)
            setData([...data])
            console.log(data)
        }
        if (item.btnName === "New") {
            data.push(1)
            setData([...data])
        }
    }
    return (
        <div className="settings_content_container">
            <p className="fs_14 text_left" style={{maxWidth: "690px"}}>Choose which shipment-related information you
                want to share automatically
                with differentparties.
                The choices made are applied to all users in your company.</p>
            <div className="d_flex align_items_start settings_tab_content margin_top_24">
                <div className="notification_tab_content_1 d_flex space_between">
                    <div className="dropdown_div d_flex fd_column">
                        <label htmlFor="" className="text_left f_600 fs_14">Recipient</label>
                        <div className="dropdown_block d_flex fd_column">
                            {
                                recipientData.map((item, index) => {
                                    return (
                                        <Dropdown
                                            key={index}
                                            data={item.data}
                                            name={item.name}
                                            filterItemDsta={setRecipientData}
                                            changeDropdown={(itemDataIndex) => {
                                                let data = setRecipientData[index].data
                                                for (let i = 0; i < setRecipientData[index].data.length; i++) {
                                                    data[i].checked = false
                                                }
                                                data[itemDataIndex].checked = true
                                                setRecipientData([...recipientData])
                                                // setFilterData({...filterData, [item.key]: data[itemDataIndex].name})
                                            }}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="dropdown_div d_flex fd_column">
                        <label htmlFor="" className="text_left f_600 fs_14">Notification</label>
                        <div className="dropdown_block d_flex fd_column">
                            {
                                notificationData.map((item, index) => {
                                    return (
                                        <Dropdown
                                            key={index}
                                            data={item.data}
                                            name={item.name}
                                            filterItemDsta={setNotificationData}
                                            changeDropdown={(itemDataIndex) => {
                                                let data = setNotificationData[index].data
                                                for (let i = 0; i < setNotificationData[index].data.length; i++) {
                                                    data[i].checked = false
                                                }
                                                data[itemDataIndex].checked = true
                                                setNotificationData([...notificationData])
                                                // setFilterData({...filterData, [item.key]: data[itemDataIndex].name})
                                            }}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="d_flex fd_column notification_tab_content_2">
                    <div className="d_flex align_items_center notification_tab_content_2_1">
                        <label htmlFor="" className="fs_14 f_600">Import</label>
                        <label htmlFor="" className="fs_14 f_600">Export</label>
                        <label htmlFor="" className="fs_14 f_600"></label>
                    </div>
                    {
                        data.map((item, index) => {
                            return (
                                <div className="d_flex space_between notification_tab_content_2_inner margin_top_16">
                                    <div className="d_flex align_items_center import_b">
                                        <input type="checkbox" checked={item.import}/>
                                    </div>
                                    <div className="d_flex align_items_center export_b">
                                        <input type="checkbox" checked={item.export}/>
                                    </div>
                                    <button className={item.btnName === "New" ? "bc_green btn_new" : "btn_delete"}
                                            onClick={() => handleClickBtn(item, index)}>{item.btnName}</button>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}
