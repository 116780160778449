import "../../style.css";
import phone_icon from "../../../../../../../assets/images/icons/call_grey_icon.png";
import email_grey_icon from "../../../../../../../assets/images/icons/email_grey_icon.png";
import CO2_pic from "../../../../../../../assets/images/CO2_pic.png";
import {useNavigate} from "react-router-dom";

export default function TransportShipment({tableShipmentItem}){
    let navigate=useNavigate()


    let handleNavigateTransportShipmentDetails = (id,item) => {
        navigate(`/dashboard/shipments/new_shipment/transport_book/${id}`, {state: item})
    }

    return (
        <div>
            <table id="tableShipment" className="margin_top_12">
                <tr>
                    <th>Services</th>
                    <th>Collection / Delivery</th>
                    <th>Estimate delivery date</th>
                    <th>CO₂e</th>
                    <th>Price</th>
                    <th></th>
                </tr>
                {
                    tableShipmentItem.map((item,index)=>{
                        return (
                            <tr key={index}   onClick={() => handleNavigateTransportShipmentDetails(item.id,item)}>
                                <td className="d_flex align_items_center"><img src={item.logo} alt="icon"/><p>{item.name}</p>
                                    <img src={phone_icon} alt="icon"/> <img src={email_grey_icon} alt="icon"/></td>
                                <td>{item.collectionDeliveryDate}</td>
                                <td>{item.EstimateDate}</td>
                                <td className="d_flex align_items_center justify_content_center"><img src={CO2_pic} alt="pic" className="COC_pic"/>{item.CO}</td>
                                <td className="">{item.price  ? item.price : <button className="request_btn fs_16">Request</button>}</td>
                                <td><button className="book_now_btn fs_16">Book now</button></td>
                            </tr>
                        )
                    })
                }
            </table>
        </div>
    )
}