import "../../style.css";
import schedule_black_icon from "../../../../../assets/images/icons/schedule_black_icon.png";
import ReactDatepicker from "./ReactDataPicker";
import React, {useState} from "react";
import DatePicker from "react-datepicker";



export default function ReadyCollection(){
    const [basicDate, setBasicDate] = useState(new Date());
    let [showSchedule, setShowSchedule]=useState(false)
    return (
        <div className="ready_input_b d_flex fd_column">
            <label htmlFor="" className="text_left fs_20 f_600">Ready for collection</label>
            <div className="d_flex fd_column margin_top_8">
                <div className="d_flex space_between align_items_center date_input_b">
                    <div className="d_flex  align_items_center new_shipment_input_block">
                        <DatePicker
                            selected={basicDate}
                             onChange={(date) => setBasicDate(date)}
                        />
                    </div>
                    <img src={schedule_black_icon} alt="time_icon" onClick={()=>setShowSchedule(!showSchedule)}/>
                </div>
                {
                    showSchedule &&
                    <ReactDatepicker/>
                }

            </div>
        </div>
    )
}