import "../../style.css";
import schedule_black_icon from "../../../../../assets/images/icons/schedule_black_icon.png";
import React, {useState} from "react";
import ReactDatepicker from "../ready_collection/ReactDataPicker";

export default function ToDelivered(){
    let [showSchedule, setShowSchedule]=useState(false)
    return (
        <div className="delivered_input_b_outside">
            <div className="delivered_input_b d_flex fd_column">
                <label htmlFor="" className="text_left fs_20 f_600">To be delivered</label>
                <div className="d_flex fd_column margin_top_8">
                    <div className="d_flex space_between align_items_center date_input_b">
                        <div className="d_flex  align_items_center new_shipment_input_block">
                            <input type="text" placeholder="Date" className="date_input"/>
                        </div>
                        <img src={schedule_black_icon} alt="time_icon" onClick={()=>setShowSchedule(!showSchedule)}/>
                    </div>
                    {
                        showSchedule &&
                        <ReactDatepicker/>
                    }
                </div>
            </div>
        </div>
    )
}