import "./style.css";
import transfer_data_icon from "../../../../../assets/images/icons/transfer_data_icon.png";


export default function CollectionDelivered(){

    return (
        <div className="collection_delivery_blocks margin_top_24 d_flex align_items_start space_between">
            <div className="collection_b d_flex fd_column">
                <div className="collection_b_1">
                    <h2 className="fs_20 text_left f_600">Collection</h2>
                    <div className="collection_inputs_b margin_top_24 d_flex fd_column ">
                        <div className="d_flex space_between align_items_center ">
                            <div className="new_shipment_input_block first_item">
                                <input type="text" placeholder="Company*"/>
                            </div>
                            <div className="new_shipment_input_block">
                                <input type="text" placeholder="Contact person*"/>
                            </div>
                        </div>
                    </div>
                    <div className="collection_inputs_b margin_top_24 d_flex fd_column margin_top_12">
                        <div className="d_flex space_between align_items_center ">
                            <div className="new_shipment_input_block first_item">
                                <input type="text" placeholder="Street, house*"/>
                            </div>
                            <div className="new_shipment_input_block">
                                <input type="text" placeholder="Phone*"/>
                            </div>
                        </div>
                    </div>
                    <div className="collection_inputs_b margin_top_24 d_flex fd_column margin_top_12">
                        <div className="d_flex space_between align_items_center ">
                            <div className="new_shipment_input_block first_item">
                                <input type="text" placeholder="Details"/>
                            </div>
                            <div className="new_shipment_input_block">
                                <input type="text" placeholder="Email"/>
                            </div>
                        </div>
                    </div>
                    <div className="collection_inputs_b margin_top_24 d_flex space_between margin_top_12">
                        <div className="new_shipment_input_block first_item d_flex fd_column">
                            <div className="d_flex space_between">
                                <div className="new_shipment_input_block first_item">
                                    <input type="text" placeholder="Postal*"/>
                                </div>
                                <div className="new_shipment_input_block">
                                    <input type="text" placeholder="City*" />
                                </div>
                            </div>
                            <div className="new_shipment_input_block margin_top_12">
                                <input type="text" placeholder="Country"/>
                            </div>
                        </div>
                        <div className="new_shipment_input_block">
                            <textarea name="" id="" cols="" rows="">Message</textarea>
                        </div>
                    </div>
                    <div className="d_flex  margin_top_12 d_flex justify_content_start checkbox_block">
                        <input type="checkbox"/>
                        <label htmlFor="" className="fs_14">Save address</label>
                    </div>
                </div>
                <div className="additional_services_b">
                    <p className="fs_14 text_left">Additional services <span>(Charges may apply)</span></p>
                    <div className="d_flex">
                        <div className="d_flex  margin_top_12 d_flex justify_content_start checkbox_block">
                            <input type="checkbox"/>
                            <label htmlFor="" className="fs_14">Tail lift</label>
                        </div>
                        <div className="d_flex  margin_top_12 d_flex justify_content_start checkbox_block">
                            <input type="checkbox"/>
                            <label htmlFor="" className="fs_14">Notify by phone</label>
                        </div>
                    </div>
                </div>
            </div>
            <img src={transfer_data_icon} alt="transfer_data" className="transfer_data_icon"/>
            <div className="collection_b d_flex fd_column">
                <div className="collection_b_1">
                    <h2 className="fs_20 text_left f_600">Delivery</h2>
                    <div className="collection_inputs_b margin_top_24 d_flex fd_column ">
                        <div className="d_flex space_between align_items_center ">
                            <div className="new_shipment_input_block first_item">
                                <input type="text" placeholder="Company*"/>
                            </div>
                            <div className="new_shipment_input_block">
                                <input type="text" placeholder="Contact person*"/>
                            </div>
                        </div>
                    </div>
                    <div className="collection_inputs_b margin_top_24 d_flex fd_column margin_top_12">
                        <div className="d_flex space_between align_items_center ">
                            <div className="new_shipment_input_block first_item">
                                <input type="text" placeholder="Street, house*"/>
                            </div>
                            <div className="new_shipment_input_block">
                                <input type="text" placeholder="Phone*"/>
                            </div>
                        </div>
                    </div>
                    <div className="collection_inputs_b margin_top_24 d_flex fd_column margin_top_12">
                        <div className="d_flex space_between align_items_center ">
                            <div className="new_shipment_input_block first_item">
                                <input type="text" placeholder="Details"/>
                            </div>
                            <div className="new_shipment_input_block">
                                <input type="text" placeholder="Email"/>
                            </div>
                        </div>
                    </div>
                    <div className="collection_inputs_b margin_top_24 d_flex space_between margin_top_12">
                        <div className="new_shipment_input_block first_item d_flex fd_column">
                            <div className="d_flex space_between">
                                <div className="new_shipment_input_block first_item">
                                    <input type="text" placeholder="Postal*"/>
                                </div>
                                <div className="new_shipment_input_block">
                                    <input type="text" placeholder="City*"/>
                                </div>
                            </div>
                            <div className="new_shipment_input_block margin_top_12">
                                <input type="text" placeholder="Country"/>
                            </div>
                        </div>
                        <div className="new_shipment_input_block">
                            <textarea name="" id="" cols="" rows="">Message</textarea>
                        </div>
                    </div>
                    <div className="d_flex  margin_top_12 d_flex justify_content_start checkbox_block">
                        <input type="checkbox"/>
                        <label htmlFor="" className="fs_14">Save address</label>
                    </div>
                </div>
                <div className="additional_services_b">
                    <p className="fs_14 text_left">Additional services <span>(Charges may apply)</span></p>
                    <div className="d_flex">
                        <div className="d_flex  margin_top_12 d_flex justify_content_start checkbox_block">
                            <input type="checkbox"/>
                            <label htmlFor="" className="fs_14">Tail lift</label>
                        </div>
                        <div className="d_flex  margin_top_12 d_flex justify_content_start checkbox_block">
                            <input type="checkbox"/>
                            <label htmlFor="" className="fs_14">Notify by phone</label>
                        </div>
                        <div className="d_flex  margin_top_12 d_flex justify_content_start checkbox_block">
                            <input type="checkbox"/>
                            <label htmlFor="" className="fs_14">Return doc.</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}