import "./style.css"
import delete_black_icon from "../../../../../assets/images/icons/delete_black_icon.png";
import TemperatureRange from "../temperature_range/TemperatureRange";
import arrow_down from "../../../../../assets/images/icons/arrow_down_icon_black.png";
import {useEffect, useRef, useState} from "react";

export default function Goods({goodsType,valueCurrency,setValueCurrency,setGoodType}){
    let valueCurrencyRef=useRef(null)
    let closeValueCurrencyRef=useRef(null)
    let valueGoodsTypeRef=useRef(null)
    let closeValueGoodsTypeRef=useRef(null)

    let [addGoods,setAddGoods]=useState([{
        showType: false,
        showAdr:false,
        showTemperature: false,
        showGoodsValue: false,
        showValueCurrency:false
    }])

    const handleClickOutSide = (e) => {
        let valueRef = valueCurrencyRef;
        let goodsTypeRef = valueGoodsTypeRef;
        if (valueRef.current && !valueRef.current.contains(e.target) && !closeValueCurrencyRef.current.contains(e.target)) {
            for(let i = 0; i < addGoods.length; i++){
                addGoods[i].showValueCurrency = false
            }
            setAddGoods([...addGoods])
        }
        if (goodsTypeRef.current && !goodsTypeRef.current.contains(e.target) && !closeValueGoodsTypeRef.current.contains(e.target)) {
            for(let i = 0; i < addGoods.length; i++){
                addGoods[i].showType = false
            }
            setAddGoods([...addGoods])
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])

    let handleAddGoods = ()=>{
      addGoods.push({
          showType: false,
          showAdr: false,
          showTemperature: false,
          showGoodsValue: false,
      })
       setAddGoods([...addGoods])
    }

    console.log(addGoods);

    let changeState = (index, name) => {
        addGoods[index][name] = !addGoods[index][name]
        setAddGoods([...addGoods])
    }

    return (
        <div className="margin_top_24 goods_b d_flex fd_column">
            <h2 className="fs_24 f_700 text_left">Goods</h2>
            <div className="d_flex fd_column">
            </div>
            <div className="d_flex fd_column  margin_top_12">
                {
                    addGoods.map((item,index)=>{
                        return(
                            <div className="d_flex fd_column margin_top_24 goods_b_item" key={index}>
                                <div className="d_flex align_items_start">
                                    <div className="new_shipment_input_block">
                                        <input type="text" placeholder="Pcs*"/>
                                    </div>
                                    <div className="type_select_b">
                                        <div
                                            className={!item.showType ? "d_flex align_items_center select_header" : "d_flex align_items_center select_header_active"}
                                            onClick={() =>changeState(index, 'showType')}  ref={closeValueGoodsTypeRef}>
                                            <input type="text" value={addGoods[index].typeValue} placeholder="Type*"/>
                                            {
                                                item.showType ?
                                                    <img src={arrow_down} alt="arrow"
                                                         style={{transform: "rotate(180deg)"}}/>
                                                    :
                                                    <img src={arrow_down} alt="arrow"/>
                                            }

                                        </div>
                                        {
                                            item.showType &&
                                            <div className="select_dropdown" ref={valueGoodsTypeRef}>
                                                {
                                                    goodsType.map((item) => {
                                                        return (
                                                            <div className="text_left"
                                                                 onClick={()=>{
                                                                     console.log(addGoods[index].typeValue, "typeValue")
                                                                    addGoods[index].typeValue = item.option
                                                                     setAddGoods([...addGoods])
                                                                     changeState(index, 'showType')
                                                                 }}
                                                            >{ item.option}</div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }

                                    </div>
                                    <div className="new_shipment_input_block">
                                        <input type="text" placeholder="WT(kg)*"/>
                                    </div>
                                    <div className="new_shipment_input_block">
                                        <input type="text" placeholder="L(cm)*"/>
                                    </div>
                                    <div className="new_shipment_input_block">
                                        <input type="text" placeholder="W(cm)"/>
                                    </div>
                                    <div className="new_shipment_input_block">
                                        <input type="text" placeholder="H(cm)"/>
                                    </div>
                                    <div className="new_shipment_input_block">
                                        <input type="text" placeholder="VOL(m³)"/>
                                    </div>
                                    <div className="new_shipment_input_block">
                                        <input type="text" placeholder="LDM"/>
                                    </div>
                                    <div className="new_shipment_input_block">
                                        <input type="text" placeholder="Description"/>
                                    </div>
                                    <button className={!item.showAdr ? "adr_btn fs_16" : "adr_btn_active fs_16"}
                                            onClick={() => changeState(index, 'showAdr')}>ADR
                                    </button>
                                    <button className="add_btn  f_700" onClick={handleAddGoods}>+</button>
                                </div>
                                {
                                    item.showAdr &&
                                    <div className="margin_top_16 d_flex align_items_center adr_b">
                                        <div className="d_flex">
                                            <div className="new_shipment_input_block">
                                                <input type="text" placeholder="UN / DG no."/>
                                            </div>
                                            <div className="new_shipment_input_block">
                                                <input type="text" placeholder="Classification"/>
                                            </div>
                                            <div className="new_shipment_input_block">
                                                <input type="text" placeholder="Package group"/>
                                            </div>
                                            <div className="new_shipment_input_block">
                                                <input type="text" placeholder="NEQ (net weight)"/>
                                            </div>
                                        </div>
                                        <div className="new_shipment_input_block technical_desc_b">
                                            <input type="text" placeholder="Technical desc."/>
                                        </div>
                                        <img src={delete_black_icon} alt="delete_black_icon" className="delete_icon"
                                             onClick={() => changeState(index, 'showAdr')}/>
                                    </div>
                                }
                                <div className="margin_top_24 d_flex align_items_start temperature_add_value_b space_between">
                                    <div className="d_flex fd_column">
                                        <div className="d_flex d_flex justify_content_start">
                                            <input type="checkbox" onChange={() =>   changeState(index, 'showTemperature')}/>
                                            <label htmlFor="" className="fs_14">Temperature sensitive</label>
                                        </div>
                                        {item.showTemperature &&
                                        <TemperatureRange />
                                        }
                                    </div>

                                    <div className="d_flex fd_column">
                                        <div className="d_flex d_flex justify_content_start">
                                            <input type="checkbox" onChange={() => changeState(index, 'showGoodsValue')}/>
                                            <label htmlFor="" className="fs_14">Add value of goods</label>
                                        </div>
                                        {
                                            item.showGoodsValue &&
                                            <div className="margin_top_16 d_flex align_items_start">
                                                <div className="CAD_select_b">
                                                    <div
                                                        className={!item.showValueCurrency ? "d_flex align_items_center select_header" : "d_flex align_items_center select_header_active"}
                                                        onClick={() => changeState(index, 'showValueCurrency')} ref={closeValueCurrencyRef}>
                                                        <input type="text" value={addGoods[index].currencyValue} placeholder="Select"/>
                                                        {
                                                            item.showValueCurrency ?
                                                                <img src={arrow_down} alt="arrow"
                                                                     style={{transform: "rotate(180deg)"}}/>
                                                                :
                                                                <img src={arrow_down} alt="arrow"/>
                                                        }

                                                    </div>
                                                    {
                                                        item.showValueCurrency &&
                                                        <div className="select_dropdown"  ref={valueCurrencyRef}>
                                                            {
                                                                valueCurrency.map((item) => {
                                                                    return (
                                                                        <div className="text_left"
                                                                             onClick={()=>{
                                                                                 addGoods[index].currencyValue = item.option
                                                                                 setAddGoods([...addGoods])
                                                                                 changeState(index, 'showValueCurrency')
                                                                             }
                                                                             }
                                                                        >{item.option}</div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                </div>
                                                <div className="new_shipment_input_block">
                                                    <input type="text" placeholder="Value of goods"/>
                                                </div>

                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

        </div>
    )
}