import "./style.css";

import React, {useState} from "react";
import ReactSlider from "react-slider";
import "./style.css";
import Steps from "./RangerSteps";


export default function WayRangeSliderWithSteps({currentIndex, setCurrentIndex}) {


    const _handleIndexWayChange = (index) => {
        setCurrentIndex(index);
    };



    return (
        <div className="d_flex fd_column way_ranger_slider">
            <ReactSlider
                className="vertical-slider way-vertical-slider d_flex margin_top_12"
                markClassName="example-mark"
                onChange={_handleIndexWayChange}
                trackClassName="example-track"
                defaultValue={0}
                value={currentIndex}
                min={0}
                max={3}
                marks
                renderMark={(props) => {
                    if (props.key < currentIndex) {
                        props.className = "example-mark example-mark-completed";
                    } else if (props.key === currentIndex) {
                        props.className = "example-mark example-mark-active";
                    }
                    return <span {...props} />;
                }}
                orientation="horizontal"
            />
            <Steps currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
        </div>

    );
};
