import "./style.css";
import arrow_left from "../../../assets/images/icons/arrow_left.png";
import dashboard_header_icon from "../../../assets/images/icons/dashboard_header_icon.png";
import {useNavigate} from "react-router-dom";
import calendar_icon from "../../../assets/images/icons/calendar_icon.png";
import schedule_icon from "../../../assets/images/icons/schedule_black_icon.png";
import CollectionDelivery from "./components/coolection_delivery/CollectionDelivery";
import AdditionalServices from "./components/additional_services/AdditionalServices";
import TransportBookGoods from "./components/transport_book_goods/TransportBookGoods";
import DeliveryMethod from "./components/delivery_method/DeliveryMethod";
import Header from "../../../components/header/header";

export default function TransportBook() {
    let navigate = useNavigate()
    return (
        <div className="d_flex fd_column">
            <Header/>
            <div className="go_back_header_btn_b d_flex space_between align_items_start">
                <div className="d_flex align_items_center fs_18 f_600"
                     onClick={(id) => navigate(`../../dashboard/shipments/new_shipment`)}>
                    <img src={arrow_left} alt="arrow_left"/> Go Back
                </div>
            </div>
            {/*<div className="go_back_header">*/}
            {/*    <div className="go_back_header_inner d_flex align_items_start space_between">*/}
            {/*        <div className="d_flex  align_items_center fs_18 f_600"*/}
            {/*             onClick={() => navigate("../../dashboard/shipments/new_shipment")}>*/}
            {/*            <img src={arrow_left} alt="arrow_left"/> Go Back*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <img src={dashboard_header_icon} alt="logout_b"/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="new_shipment_cont d_flex fd_column justify_content_start">
                <div className="new_shipment_content_cont d_flex fd_column">
                    <div className="title_b d_flex align_items_center space_between">
                        <h2 className="fs_18 f_700">Shipment CA23850 <span style={{color: "#8B8B8B"}}>(Purchase order 12345)</span>
                        </h2>
                    </div>
                    <CollectionDelivery/>
                    <AdditionalServices/>
                    <TransportBookGoods/>
                    <DeliveryMethod/>
                    {/*<div className="d_flex justify_content_end next_btn_b" onClick={() => navigate("")}>*/}
                    {/*    <button className="next_btn bc_green fs_18 f_600">Next</button>*/}
                    {/*</div>*/}
                </div>
            </div>


        </div>
    )
}
