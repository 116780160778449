import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";


export  default  function CollectionCalendar({setCollectionDate, collectionDate}) {


    return (
        <div className="calendar_collection">
            <DatePicker selected={collectionDate} onChange={(date)=>setCollectionDate(date)}  />
        </div>
    );
}