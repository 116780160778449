import "./style.css";
import transport_delivery_icon from "../../../../../assets/images/icons/transport_delivery_icon.png";
import plane_delivery_icon from "../../../../../assets/images/icons/plane_delivery_icon.png";
import ship_delivery_icon from "../../../../../assets/images/icons/ship_delivery_icon.png";
import man_delivery_icon from "../../../../../assets/images/icons/man_delivery_icon.png";
import refresh_icon from "../../../../../assets/images/icons/refresh_icon.png";
import {useState} from "react";
import logo from "../../../../../assets/images/company_logo_shipment.png";
import TransportShipment from "./components/transport_shipment/TransportShipment";



export default function DeliveryMethod() {
    let [tableShipmentItem, setTableShipmentItem] = useState([
        {
            id: 1,
            logo: logo,
            name: "DPD",
            tel: "",
            email: "",
            collectionDeliveryDate:"02 - 11 - 2023 / 12 - 11 - 2023",
            EstimateDate:"16 - 11 - 2023",
            CO:"236.27 kg",
            price:"$ 356",
        },
        {
            id: 2,
            logo: logo,
            name: "DPD",
            tel: "",
            email: "",
            collectionDeliveryDate:"02 - 11 - 2023 / 12 - 11 - 2023",
            EstimateDate:"16 - 11 - 2023",
            CO:"236.27 kg",
            price:"",
        },
        {
            id: 3,
            logo: logo,
            name: "DPD",
            tel: "",
            email: "",
            collectionDeliveryDate:"02 - 11 - 2023 / 12 - 11 - 2023",
            EstimateDate:"16 - 11 - 2023",
            CO:"236.27 kg",
            price:"",
        },
        {
            id: 4,
            logo: logo,
            name: "DPD",
            tel: "",
            email: "",
            collectionDeliveryDate:"02 - 11 - 2023 / 12 - 11 - 2023",
            EstimateDate:"16 - 11 - 2023",
            CO:"236.27 kg",
            price:"$ 356",
        },
    ])
    let [showTransport, setShowTransport] = useState(true);
    let [showPlane, setShowPlane] = useState(false);
    let [showShip, setShowShip] = useState(false);
    let [showCourier, setShowCourier] = useState(false);

    let tabToggler = (active) => {
        if (active === "Transport"){
            setShowTransport(true);
            setShowPlane(false);
            setShowShip(false)
            setShowCourier(false);
        }
        if (active === "Plane"){
            setShowTransport(false);
            setShowPlane(true);
            setShowShip(false)
            setShowCourier(false);
        }
        if (active === "Ship"){
            setShowTransport(false);
            setShowPlane(false);
            setShowShip(true)
            setShowCourier(false);
        }
        if (active === "Courier"){
            setShowTransport(false);
            setShowPlane(false);
            setShowShip(false)
            setShowCourier(true);
        }
    }
    return (
        <div className="margin_top_24 delivery_methods_block d_flex fd_column">
            <div className="d_flex space_between">
                <div className="d_flex delivery_methods_items tabs_block">
                    <div  onClick={()=>tabToggler("Transport")} className={showTransport ? "tab_active": "tab"}>
                        <img src={transport_delivery_icon} alt="icon"/>
                    </div>
                    <div onClick={()=>tabToggler("Plane")} className={showPlane ? "tab_active": "tab"}>
                        <img src={plane_delivery_icon} alt="icon"/>
                    </div>
                    <div onClick={()=>tabToggler("Ship")} className={showShip ? "tab_active": "tab"}>
                        <img src={ship_delivery_icon} alt="icon"/>
                    </div>
                    <div onClick={()=>tabToggler("Courier")} className={showCourier ? "tab_active": "tab"}>
                        <img src={man_delivery_icon} alt="icon"/>
                    </div>
                </div>
                <button className="fs_16 d_flex align_items_center">
                    <img src={refresh_icon} alt="icon" style={{marginRight: "4px"}}/>
                    Refresh
                </button>
            </div>
            <div id="Transport" className={showTransport ? "showTab": "hideTab"}>
                <TransportShipment tableShipmentItem={tableShipmentItem} setTableShipmentItem={setTableShipmentItem}/>
            </div>

            <div id="Plane" className={showPlane ? "showTab": "hideTab"}>
                <TransportShipment tableShipmentItem={tableShipmentItem} setTableShipmentItem={setTableShipmentItem}/>
            </div>
            <div id="Ship" className={showShip ? "showTab": "hideTab"}>
              rfdsfdf
            </div>
            <div id="Courier" className={showCourier ? "showTab": "hideTab"}>
               dgdg
            </div>
        </div>
    )
}