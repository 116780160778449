import React, {useState} from "react";
import ReactSlider from "react-slider";
import "./style.css";


const RangeSliderWithSteps = ({currentIndex,setCurrentIndex}) => {


    const _handleIndexChange = (index) => {
        setCurrentIndex(index);
    };

    return (
        <ReactSlider
            className="vertical-slider d_flex"
            markClassName="example-mark"
            onChange={_handleIndexChange}
            trackClassName="example-track"
            defaultValue={0}
            value={currentIndex}
            min={0}
            max={2}
            marks
            renderMark={(props) => {
                if (props.key < currentIndex) {
                    props.className = "example-mark example-mark-completed";
                } else if (props.key === currentIndex) {
                    props.className = "example-mark example-mark-active";
                }
                return <span {...props} />;
            }}
            orientation="horizontal"
        />
    );
};

export default RangeSliderWithSteps;