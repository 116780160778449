import React, {useState} from 'react';
import { Outlet, Navigate} from 'react-router-dom';
import config from '../../config'
export default function  PrivateRoute () {
    console.log(config.token,"3535");
    let token=localStorage.getItem('jwtToken') ? JSON.parse(localStorage.getItem('jwtToken')) : JSON.parse(sessionStorage.getItem('jwtToken'))
    return(
        token ? <Outlet/> : <Navigate to="/sign_in"/>
    )
}

