import "../details_good/style.css";
import React from "react";

export default function Steps({currentIndex, setCurrentIndex}) {
    const steps = ["Confirmed", "Collected", "Delivered", "Delivered"];
    const stepsDate=["02.11 13:07", "", "", ""];

    return (
        <div className="d_flex fd_column justify_content_start steps_items_cont">
            <div className="steps_container d_flex space_between margin_top_12">
                {steps.map((step, index) => {
                    let color = currentIndex === index ? "#333" : "#8B8B8B";
                    let fontWeight = currentIndex === index ? "600" : "400";
                    console.log("color", color);
                    return (
                        <div className="steps_item d_flex fd_column fs_14">
                            <h3
                                style={{
                                    margin: 0,
                                    color: color,
                                    fontWeight: fontWeight
                                }}
                            >
                                {step}
                            </h3>

                        </div>
                    );
                })}
            </div>
            <div className="steps_date_item d_flex space_between fs_14 margin_top_8">
                {stepsDate.map((item,index)=>{
                    return (
                        <h3 className="" style={{color:"#8B8B8B"}}>{item}</h3>
                    )
                })
                }
            </div>
        </div>



    )
}