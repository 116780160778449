import "../../../style.css";
import phone_icon from "../../../../../assets/images/icons/call_grey_icon.png";
import email_grey_icon from "../../../../../assets/images/icons/email_grey_icon.png";
import CO2_pic from "../../../../../assets/images/CO2_pic.png";
import discussion_icon from "../../../../../assets/images/icons/discussion_icon.png";
import Messenger from "./Messenger";


export default function Discussion({data}) {

    return (
        <div className="discussion_cont margin_top_48">
            <div className="" style={{position:"relative"}}>
                <button className="discussion_btn fs_14 f_600 d_flex align_items_center"><img src={discussion_icon} alt="icon"/>Discussion</button>
            </div>
                <table id="tableShipmentDetails">

                    <tr className="">
                        <th></th>
                        <th>Services</th>
                        <th>Collection / Delivery</th>
                        <th>CO₂e</th>
                        <th>Price</th>
                        <th></th>
                    </tr>

                    <tr className="">
                        <td><img src={data.logo} alt="icon"/></td>
                        <td className="d_flex align_items_center space_between"><p>{data.name}</p>
                            <img src={phone_icon} alt="icon"/> <img src={email_grey_icon} alt="icon"/></td>
                        <td>{data.collectionDeliveryDate}</td>
                        <td>{data.EstimateDate}</td>
                        <td className="d_flex align_items_center justify_content_center"><img src={CO2_pic} alt="pic"
                                                                                              className="COC_pic"/>{data.CO}
                        </td>
                        <td className="">
                            {data.price ? data.price : <button className="request_btn fs_16">Request</button>}
                        </td>
                        <td>
                            <button className="book_now_btn fs_16 bc_green">Booked</button>
                        </td>
                    </tr>
                </table>
            <Messenger/>


        </div>
    )
}