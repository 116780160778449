import "./style.css";
import {useState} from "react";
import delivery_track from "../../../../../assets/images/icons/delivery_icon.png";
import add_icon from "../../../../../assets/images/icons/add_icon_black.png";
import attach_icon from "../../../../../assets/images/icons/attach_icon.png";
import union_blue from "../../../../../assets/images/union_blue.png";
import union_green from "../../../../../assets/images/union_green.png";
import RangerSliderWithSteps from "../ranger_slider_with_steps/RangerSliderWithSteps";

export default function ShipmentItems() {
    let [query, setQuery] = useState([
        {
            id: 1,
            trackNumber: "CA23850",
            code: "MyREF/1234567",
            img: delivery_track,
            responsibleName: "Demo User",
            responsibleDate: "28.09.2023",
            responsibleTime: " 17:40",
            collectionName: "Product Demo",
            collectionDate: "31-07-2023",
            collectionNumber: "EE 10619,",
            collectionCountry: "Talilinn",
            deliveryName: "Belgium Company",
            deliveryNumber: "BE 1000",
            deliveryCountry: "Bruxelles",
            goodsCount: "3",
            goodsWeight: "975 kg",
            goodsLength: "1.4 LDM",
            price: "0,00",
            carrierName: "Demo Carrier",
            state: "New",
            currentIndex:0,
        },
        {
            id: 2,
            trackNumber: "CA23850",
            code: "MyREF/1234567",
            img: delivery_track,
            responsibleName: "Demo User",
            responsibleDate: "28.09.2023",
            responsibleTime: " 17:40",
            collectionName: "Product Demo",
            collectionDate: "31-07-2023",
            collectionNumber: "EE 10619,",
            collectionCountry: "Talilinn",
            deliveryName: "Belgium Company",
            deliveryNumber: "BE 1000",
            deliveryCountry: "Bruxelles",
            goodsCount: "3",
            goodsWeight: "975 kg",
            goodsLength: "1.4 LDM",
            price: "0,00",
            carrierName: "Demo Carrier",
            state: "New",
            currentIndex:0,
        },
        {
            id: 3,
            trackNumber: "CA23850",
            code: "MyREF/1234567",
            img: delivery_track,
            responsibleName: "Demo User",
            responsibleDate: "28.09.2023",
            responsibleTime: " 17:40",
            collectionName: "Product Demo",
            collectionDate: "31-07-2023",
            collectionNumber: "EE 10619,",
            collectionCountry: "Talilinn",
            deliveryName: "Belgium Company",
            deliveryNumber: "BE 1000",
            deliveryCountry: "Bruxelles",
            goodsCount: "3",
            goodsWeight: "975 kg",
            goodsLength: "1.4 LDM",
            price: "0,00",
            carrierName: "Demo Carrier",
            state: "New",
            currentIndex:2,
        },
        {
            id: 4,
            trackNumber: "CA23850",
            code: "MyREF/1234567",
            img: delivery_track,
            responsibleName: "Demo User",
            responsibleDate: "28.09.2023",
            responsibleTime: " 17:40",
            collectionName: "Product Demo",
            collectionDate: "31-07-2023",
            collectionNumber: "EE 10619,",
            collectionCountry: "Talilinn",
            deliveryName: "Belgium Company",
            deliveryNumber: "BE 1000",
            deliveryCountry: "Bruxelles",
            goodsCount: "3",
            goodsWeight: "975 kg",
            goodsLength: "1.4 LDM",
            price: "0,00",
            carrierName: "Demo Carrier",
            state: "Booked",
            currentIndex:3,
        },
    ])
    const [currentIndex, setCurrentIndex] = useState(1);
    console.log(query.state,"rdrdfd")
    return (
        <div className="shipment_items_cont_scroll margin_top_32" >
            <div className="shipment_items_cont ">
                <div className="d_flex fd_column shipment_items_cont_table">
                    <div className="d_flex space_between shipment_items_cont_table_header text_left">
                        <div className="d_flex space_between shipment_items_cont_table_header_b1">
                            <p className="fs_20 f_700">Reference</p>
                            <p className="fs_20 f_700">Responsible</p>
                            <p className="fs_20 f_700">Collection</p>
                            <p className="fs_20 f_700">Delivery</p>
                        </div>
                        <div className="d_flex space_between  shipment_items_cont_table_header_b2">
                            <p className="fs_20 f_700">Goods</p>
                            <p className="fs_20 f_700">Price</p>
                            <p className="fs_20 f_700">Carrier</p>
                            <p className="fs_20 f_700"> </p>
                        </div>
                    </div>
                        <div className="d_flex fd_column shipment_items_cont_table_content">
                            {
                                query.map((item, index) => {
                                    return (
                                        <div className="d_flex fd_column shipment_items_cont_table_item margin_top_12">
                                            <div className="d_flex space_between shipment_items_cont_table_item_items">
                                                <div className="d_flex space_between shipment_items_cont_table_item_items_b1">
                                                    <div
                                                        className="d_flex align_items_start shipment_items_cont_table_item_item">
                                                        <input type="checkbox" style={{marginRight: "12px"}}
                                                               className="input_checkbox"/>
                                                        <div className="d_flex fd_column align_items_start">
                                                            <div className="f_600 fs_18">{item.trackNumber}</div>
                                                            <div className="fs_14 margin_top_8">{item.code}</div>
                                                            <img src={delivery_track} alt="" className="margin_top_8"/>
                                                        </div>
                                                    </div>
                                                    <div className="shipment_items_cont_table_item_item">
                                                        <p className="f_600 fs_18">{item.responsibleName}</p>
                                                        <div className="d_flex margin_top_8">
                                                            <div className="fs_14">{item.responsibleDate}</div>
                                                            <div className="fs_14"
                                                                 style={{marginLeft: "12px"}}>{item.responsibleTime}</div>
                                                        </div>
                                                    </div>
                                                    <div className="shipment_items_cont_table_item_item">
                                                        <p className="f_600 fs_18">{item.collectionName}</p>
                                                        <div className="d_flex margin_top_8">
                                                            <div className="fs_14 ">{item.collectionDate}</div>
                                                            <div className="fs_14"
                                                                 style={{marginLeft: "12px"}}>{item.collectionNumber}</div>
                                                        </div>
                                                        <div className="fs_14 margin_top_8">{item.collectionCountry}</div>
                                                    </div>
                                                    <div className="shipment_items_cont_table_item_item">
                                                        <p className="f_600 fs_18">{item.deliveryName}</p>
                                                        <div className="d_flex margin_top_8">
                                                            <div className="fs_14 ">{item.deliveryNumber}</div>
                                                            <div className="fs_14"
                                                                 style={{marginLeft: "12px"}}>{item.deliveryCountry}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d_flex space_between shipment_items_cont_table_item_items_b2">
                                                    <div className="shipment_items_cont_table_item_item">
                                                        <p className="f_600 fs_18">{item.goodsCount} pcs</p>
                                                        <div className="margin_top_8 fs_14">{item.goodsWeight}</div>
                                                        <div className="margin_top_8 fs_14">{item.goodsLength}</div>
                                                    </div>

                                                    <div className="shipment_items_cont_table_item_item">
                                                        <p className="f_600 fs_18">$ {item.price}</p>
                                                    </div>
                                                    <div className="shipment_items_cont_table_item_item">
                                                        <p className="f_600 fs_18">{item.carrierName}</p>
                                                    </div>
                                                    <div className="d_flex fd_column">
                                                        <div className="state_block">
                                                            {
                                                                item.state==="New" ?
                                                                    <img src={union_blue} alt="union_blue"/>
                                                                    :
                                                                    <img src={union_green} alt="union_green"/>
                                                            }

                                                            <p className={  item.state==="New" ? "c_white f_700":"f_700"}>{item.state}</p>
                                                        </div>
                                                        <div className="d_flex attach_icon_b">
                                                            <img src={add_icon} alt="add_icon" className="add_icon"/>
                                                            <img src={attach_icon} alt="add_icon" className="attach_icon"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d_flex justify_content_center" >
                                                <RangerSliderWithSteps currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                </div>
            </div>
        </div>

    )
}