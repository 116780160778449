import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import config from "../../config";
import Header from "../../components/header/header";
import sign_up_pic from "../../assets/images/sign_up_pic.svg";
import Footer from "../../components/footer/footer";


export default function RestorePassword(){
    let {state}=useLocation()
    console.log(state,"/email_send")
        let navigate = useNavigate()

        return (
            <div className="main">
                <div>
                    <Header/>
                    <div className="sign_up_cont forget_pass_cont">
                        <div className="container d_flex align_items_center space_between">
                            <div className="sign_up_cont_sign_up text_left d_flex fd_column justify_content_start">
                                <div className="c_white fs_20 d_flex fd_column">
                                    Reset password link was sent to
                                    <div className="c_white fs_20 margin_top_8">
                                       <span className="f_700 c_white">{state.forgotPass.email}</span> address
                                    </div>
                                </div>
                                {/*<div className="margin_top_16 have_account_b fs_18 text_center">*/}
                                {/*    Already have an account?*/}
                                {/*    <span className="c_green fs_18 f_600"*/}
                                {/*          onClick={() => navigate("/sign_in")}>Sign in</span>*/}
                                {/*</div>*/}
                            </div>
                            <div className="sign_up_cont_img_b">
                                <img src={sign_up_pic} alt="sign_up_pic" className="sign_up_pic"/>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer/>
            </div>
        )
}