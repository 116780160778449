import "./style.css";
import {useState} from "react";
import WayRangerSliderWithSteps from "./WayRangerSliderWithSteps";
import local_offer from "../../../../../assets/images/icons/local_offer_black.png";


export default function TransportBookDetailsGoods() {
    const [currentIndex, setCurrentIndex] = useState(1);
    let [product, setProduct] = useState([
        {
            id: 1,
            content: "Product B"
        },
        {
            id: 2,
            content: "Product C"
        },
    ])
    let handleClickDelete = (index) => {
       product.splice(index,1)
        setProduct([...product])
    }
    console.log(product, "tee")
    return (
        <div className="transport_book_goods_block d_flex">
            <div className="transport_book_goods_block1 d_flex fd_column justify_content_start align_items_start">
                <p className="fs_14" style={{color: "#8B8B8B"}}>Goods</p>
                <div className="d_flex align_items_center" style={{marginTop: "4px"}}>
                    <div className="fs_14 f_600">
                        3 EUR pallets
                    </div>
                    <div className="fs_14 f_600">
                        120x80x100 cm
                    </div>
                    <div className="fs_14 f_600">
                        1234 kg
                    </div>
                    <div className="fs_14 f_600">
                        1.2 LDM
                    </div>
                    <div className="fs_14 f_600">
                        5.76 m³
                    </div>
                    <div className="fs_14 f_600">
                        Spare Parts
                    </div>
                </div>
                <div className="way_b margin_top_24">
                    <p className="fs_14 text_left">Way</p>
                    <WayRangerSliderWithSteps currentIndex={currentIndex} setCurrentIndex={setCurrentIndex}/>
                </div>
            </div>
            <div className="transport_book_goods_block2 d_flex fd_column justify_content_start align_items_start">
                <p className="fs_14" style={{color: "#8B8B8B"}}>Info for the carrier:</p>
                <div className="d_flex fd_column" style={{marginTop: "4px", width: "100%"}}>
                    <div className="fs_14 f_600 text_left">
                        “Lorem ipsum dolor sit amet consectetur.”
                    </div>
                    <div className="input_block margin_top_12">
                        <div className="d_flex align_items_center">
                            {product.map((item, index) => {
                                return (
                                    <div className="local_offer_item_b d_flex align_items_center" key={index}>
                                        <p onClick={() => handleClickDelete(index)}>X</p>
                                        <div className="local_offer_item fs_16">{item.content}</div>
                                    </div>
                                )
                            })

                            }
                        </div>
                        <img src={local_offer} alt="" className="local_offer_icon"/>
                    </div>
                    <div className="new_shipment_input_block margin_top_12">
                        <input type="text" placeholder="Lorem ipsum dolor sit amet consectetur." className="fs_14"/>
                    </div>
                    <div className="new_shipment_input_block margin_top_12">
                        <input type="text" placeholder="13DG57" className="fs_14"/>
                    </div>

                </div>
            </div>
        </div>
    )
}