import "../../../style.css";
import Header from "../../../../../components/header/header";
import plus_icon from "../../../../../assets/images/icons/plus_icon.png";
import search_icon from "../../../../../assets/images/icons/search_icon.png";
import checked_icon from "../../../../../assets/images/icons/checkmark_icon.png";
import delete_icon from "../../../../../assets/images/icons/delete_black_icon.png";
import arrow_left from "../../../../../assets/images/icons/arrow_left.png";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export default function AddCarrier() {
    let navigate = useNavigate()
    let [searchCarrierText, setSearchCarrierText] = useState("")

    let handleChangeSearchCarrier = (e) => {
        setSearchCarrierText(e.target.value)
    }
    return (
        <div className="d_flex fd_column">
            <Header/>
            <div className="go_back_header_btn_b d_flex space_between align_items_start">
                <div className="d_flex align_items_center fs_18 f_600"
                     onClick={() => navigate("../../dashboard/carriers")}>
                    <img src={arrow_left} alt="arrow_left"/> Go Back
                </div>
            </div>
            <div className="carriers_container d_flex fd_column justify_content_start">
                <div className="d_flex space_between carriers_title_b">
                    <h2 className="f_700 text_left">Add carrier</h2>
                </div>
                <div className="add_carrier_cont margin_top_32">
                    <div className="search_block">
                        <img src={search_icon} alt="search"/>
                        <input type="text" placeholder="Search"
                               onChange={(e) => handleChangeSearchCarrier(e)}/>
                    </div>
                    {
                        searchCarrierText ?
                            <div className="d_flex fd_column">
                                <div className="carrier_search_item margin_top_32">
                                    <div className="fs_18 f_600 text_left search_title">{searchCarrierText}</div>
                                    <div className="search_title">
                                        <p className="text_left fs_14 f_600">Services to include:</p>
                                        <div className="margin_top_24 d_flex align_items_center carriers_checkboxes_b">
                                            <div className="d_flex align_items_center">
                                                <input type="checkbox"/>
                                                <label htmlFor="">International Priority</label>
                                            </div>
                                            <div className="d_flex align_items_center">
                                                <input type="checkbox" checked="checked"/>
                                                <label htmlFor="">International Economy</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="text_left margin_top_16">
                                    Didn’t find the carrier your were looking for?
                                    <span className="f_700"> Request a new carrier here </span>
                                </div>
                            </div>
                            :
                            <div className="text_left margin_top_16">
                                Didn’t find the carrier your were looking for?
                                <span className="f_700"> Request a new carrier here </span>
                            </div>

                    }

                    <div className="d_flex justify_content_end align_items_end margin_top_24">
                        <button className="bc_green save_btn fs_18 f_600">Save</button>
                    </div>
                </div>


            </div>
        </div>
    )
}