import  "../../style.css";
import calendar_icon from "../../../../../assets/images/icons/calendar_icon.png";
import schedule_icon from "../../../../../assets/images/icons/schedule_black_icon.png";

export default function CollectionDelivery(){
    return (
        <div
            className="collection_deliver_b d_flex fd_column align_items_start space_between margin_top_12">
            <div className="collection_deliver_b_header">
                <p className="fs_16 f_600 text_left">John Smith(Demo Company)</p>
            </div>
            <div className="d_flex collection_deliver_b_content">
                <div className="collection_block d_flex fd_column align_items_start justify_content_start">
                    <p className="fs_14">Collection</p>
                    <h2 className="fs_18 f_600 margin_top_8">Demo Company</h2>
                    <p className="fs_14 margin_top_8" style={{color: "#646464"}}>Lgj.kushtrimi Vlore,
                        Albania</p>
                    <div className="margin_top_8 d_flex align_items_center" style={{color:"#8B8B8B"}}>
                        <div className="fs_12">
                            John Smith
                        </div>
                        <div className="fs_12" style={{margin:"0px 8px"}}>
                            +1 345 677 879
                        </div>
                        <div className="fs_12">
                            john@sm.example.com
                        </div>
                    </div>
                    <div className="d_flex align_items_center margin_top_12">
                        <img src={calendar_icon} alt="calendar_icon" style={{width:"18px",height:"18px"}}/>
                        <div style={{marginLeft:"4px"}}>-</div>
                    </div>
                    <div className="d_flex align_items_center" style={{marginTop:"4px"}}>
                        <img src={schedule_icon} alt="schedule_icon" style={{width:"18px",height:"18px"}}/>
                        <div style={{marginLeft:"4px"}}>-</div>
                    </div>
                </div>
                <div className="deliver_block d_flex fd_column align_items_start justify_content_start">
                    <p className="fs_14">Delivery</p>
                    <h2 className="fs_18 f_600 margin_top_8">Demo Company</h2>
                    <p className="fs_14 margin_top_8" style={{color: "#646464"}}>Lgj.kushtrimi Vlore,
                        Albania</p>
                    <div className="margin_top_8 d_flex align_items_center" style={{color:"#8B8B8B"}}>
                        <div className="fs_12">
                            John Smith
                        </div>
                        <div className="fs_12" style={{margin:"0px 8px"}}>
                            +1 345 677 879
                        </div>
                        <div className="fs_12">
                            john@sm.example.com
                        </div>
                    </div>
                    <div className="d_flex align_items_center margin_top_12">
                        <img src={calendar_icon} alt="calendar_icon" style={{width:"18px",height:"18px"}}/>
                        <div style={{marginLeft:"4px"}}>-</div>
                    </div>
                    <div className="d_flex align_items_center" style={{marginTop:"4px"}}>
                        <img src={schedule_icon} alt="schedule_icon" style={{width:"18px",height:"18px"}}/>
                        <div style={{marginLeft:"4px"}}>-</div>
                    </div>
                </div>
            </div>
        </div>
    )
}