import "./style.css";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import * as React from 'react';


export default function TemperatureRange(){
    const marks = [
        {
            value: -30,
            label: '-30°C',
        },
        {
            value: 30,
            label: '30°C',
        },
    ];

    function valuetext(value) {
        return `${value}°C`;
    }
    return (
        <Box>
            <Slider
                aria-label="Always visible"
                defaultValue={0}
                getAriaValueText={valuetext}
                step={10}
                marks={marks}
                min={-30}
                max={30}
                valueLabelDisplay="on"
                className="temperature_range_slider"
            />
        </Box>
    )
}