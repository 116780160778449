import "./style.css";
import {NavLink, useNavigate} from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import arrow_down_icon from "../../assets/images/icons/arrow_down_icon_black.png";
import {useEffect, useRef, useState} from "react";
import menu_burger_icon from "../../assets/images/icons/menu_burger_icon.png";
import dashboard_header_icon from "../../assets/images/icons/dashboard_header_icon.png";
import axios from "axios";
import config from "../../config";
import Dropdown from "../dropdown/Dropdown";


export default function Header() {
    let navigate = useNavigate()
    let [sidebarActive, setSidebarActive] = useState(false)
    let [error, setError] = useState("")
    const [showDashHeaderDropdown, setShowDashHeaderDropdown] = useState(false)
    // let token=localStorage.getItem('jwtToken') ? JSON.parse(localStorage.getItem('jwtToken')) : JSON.parse(sessionStorage.getItem('jwtToken'))
    let token=localStorage.getItem('jwtToken') ? localStorage.getItem('jwtToken') : sessionStorage.getItem('jwtToken')

    let dashboardHeaderDropdown = useRef(null)
    let closeDashboardHeaderDropdown = useRef(null)

    const handleClickOutSide = (e) => {
        let DHeaderRef = dashboardHeaderDropdown;
        let closeDHeaderRef =  closeDashboardHeaderDropdown;
        if (DHeaderRef.current && !DHeaderRef.current.contains(e.target) && !closeDHeaderRef.current.contains(e.target)) {
            setShowDashHeaderDropdown(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutSide, true)
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide, true)
        }
    }, [])




    let logOutUser = async (item, index) => {
        console.log("logout")
        localStorage.removeItem('jwtToken')
        sessionStorage.removeItem('jwtToken')
        navigate("/sign_in")
        // try {
        //     const response = await axios.get(`${config.url}/auth/sign-out`,{
        //         headers: {
        //             'authorization': `${token}`
        //         }}
        //     )
        //     localStorage.removeItem('jwtToken')
        //     console.log(response, "logout response")
        //     navigate("/sign_in")
        //
        // }catch(error){
        //     setError(error)
        // }
    }
    return (
        <>
            {
                !token ?
                    <header className="bc-white header_login_container">
                        <div className="container">
                            <div className="container_inner">
                                <div className="header_container_inner d_flex align_items_center space_between">
                                    <div onClick={() => navigate("/")}><img src={logo} alt="logo" className="logo"/>
                                    </div>

                                    <div className="d_flex align_items_center space_between f-500">
                                        <div
                                            className="d_flex align_items_center space_between header_login_content f-500">
                                            <div className="header_btn f_600 fs_18 d_flex align_items_center">
                                                Services <img src={arrow_down_icon} alt="arrow_down"/>
                                            </div>
                                            <NavLink
                                                className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn f_600'}
                                                to="/about">About Us</NavLink>
                                            <NavLink
                                                className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn f_600'}
                                                to="/help">Help</NavLink>
                                            <NavLink
                                                className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn f_600'}
                                                to="/tracking">Tracking</NavLink>
                                            <NavLink
                                                className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn f_600'}
                                                to="/pricing">Pricing</NavLink>
                                        </div>
                                        <div className="menu_burger_block"
                                             onClick={() => setSidebarActive(!sidebarActive)}>
                                            <img src={menu_burger_icon} alt="menu_burger_icon"
                                                 className="menu_burger_icon"/>
                                        </div>
                                        {
                                            sidebarActive ?
                                                <div className="d_flex fd_column header_login_content_mobile f-500">
                                                    <div className="header_btn f_600 fs_18 d_flex align_items_center">
                                                        Services <img src={arrow_down_icon} alt="arrow_down"/>
                                                    </div>
                                                    <div className="d_flex fd_column">
                                                        <NavLink
                                                            className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn f_600'}
                                                            to="/about">About Us</NavLink>
                                                        <NavLink
                                                            className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn f_600'}
                                                            to="/help">Help</NavLink>
                                                        <NavLink
                                                            className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn f_600'}
                                                            to="/tracking">Tracking</NavLink>
                                                        <NavLink
                                                            className={({isActive}) => isActive ? 'header_btn_active f_600' : 'header_btn f_600'}
                                                            to="/pricing">Pricing</NavLink>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }

                                        <div className="get_started_b">
                                            <button className="get_started_btn f_600 bc_green"
                                                    onClick={() => navigate("/sign_up")}>Get Started
                                            </button>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </header>
                    :
                    <div className="go_back_header d_flex space_between align_items_start">
                        <div className="d_flex align_items_center fs_18 f_600">
                        </div>
                        <div className="dashboard_header_dropdown">
                            <div className="dashboard_header_dropdown_header" onClick={()=>setShowDashHeaderDropdown(!showDashHeaderDropdown)} ref={closeDashboardHeaderDropdown}>
                                <img src={dashboard_header_icon} alt="logout_b"/>
                            </div>
                            {
                                showDashHeaderDropdown &&
                                <div className="dashboard_header_dropdown_body d_flex fd_column" ref={dashboardHeaderDropdown}>
                                    <div onClick={()=>navigate("/dashboard/settings")}>Settings</div>
                                    <div onClick={()=>navigate("/dashboard/carriers")}>My carriers</div>
                                    <div  onClick={logOutUser}>Log Out</div>
                                </div>
                            }

                        </div>
                    </div>
            }
        </>

    )
}
